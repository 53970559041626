/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerModel } from '../models/CustomerModel';
import type { PriceListModel } from '../models/PriceListModel';
import type { ServiceModel } from '../models/ServiceModel';
import type { WorkLogModel } from '../models/WorkLogModel';
import type { WorkplaceModel } from '../models/WorkplaceModel';
import type { WorkplaceModelCreation } from '../models/WorkplaceModelCreation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WorkplaceControllerService {

    /**
     * @returns WorkplaceModel All workplaces
     * @throws ApiError
     */
    public static getWorkplaces(): CancelablePromise<Array<WorkplaceModel>> {
        return __request({
            method: 'GET',
            path: `/workplaces`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id
     * @returns WorkplaceModel Success
     * @throws ApiError
     */
    public static getWorkplace(
        id: string,
    ): CancelablePromise<WorkplaceModel> {
        return __request({
            method: 'GET',
            path: `/workplaces/${id}`,
            errors: {
                401: `Unauthorized`,
                404: `Workplace not found`,
            },
        });
    }

    /**
     * @param workplaceNo
     * @param requestBody
     * @returns WorkplaceModel Workplace created
     * @throws ApiError
     */
    public static updateWorkplace(
        workplaceNo: number,
        requestBody?: WorkplaceModelCreation,
    ): CancelablePromise<WorkplaceModel> {
        return __request({
            method: 'PUT',
            path: `/workplaces/${workplaceNo}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id
     * @returns CustomerModel Success
     * @throws ApiError
     */
    public static getWorkplaceCustomers(
        id: string,
    ): CancelablePromise<Array<CustomerModel>> {
        return __request({
            method: 'GET',
            path: `/workplaces/${id}/customers`,
            errors: {
                401: `Unauthorized`,
                404: `Workplace not found`,
            },
        });
    }

    /**
     * @param id
     * @returns PriceListModel Success
     * @throws ApiError
     */
    public static getWorkplacePricelist(
        id: string,
    ): CancelablePromise<PriceListModel> {
        return __request({
            method: 'GET',
            path: `/workplaces/${id}/priceList`,
            errors: {
                401: `Unauthorized`,
                404: `Workplace not found`,
            },
        });
    }

    /**
     * @param id
     * @returns ServiceModel Success
     * @throws ApiError
     */
    public static getWorkplaceCustomerServices(
        id: string,
    ): CancelablePromise<Array<ServiceModel>> {
        return __request({
            method: 'GET',
            path: `/workplaces/${id}/services`,
            errors: {
                401: `Unauthorized`,
                404: `Workplace not found`,
            },
        });
    }

    /**
     * @param id
     * @returns WorkLogModel Success
     * @throws ApiError
     */
    public static getWorkplaceLog(
        id: string,
    ): CancelablePromise<Array<WorkLogModel>> {
        return __request({
            method: 'GET',
            path: `/workplaces/${id}/messages`,
            errors: {
                401: `Unauthorized`,
                404: `Workplace not found`,
            },
        });
    }

    /**
     * @param workplaceId
     * @param messageId
     * @returns WorkLogModel Message deleted
     * @throws ApiError
     */
    public static deleteWorkplaceLogMessageAsync(
        workplaceId: string,
        messageId: string,
    ): CancelablePromise<WorkLogModel> {
        return __request({
            method: 'DELETE',
            path: `/workplaces/${workplaceId}/messages/${messageId}`,
            errors: {
                401: `Unauthorized`,
                404: `Message not found`,
            },
        });
    }

}