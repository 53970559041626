import {
	ClockCircleOutlined,
	EuroCircleOutlined,
	HistoryOutlined,
	InfoCircleTwoTone,
	QuestionCircleFilled,
	ScissorOutlined,
} from "@ant-design/icons";
import { Badge, Icon, List, SwipeAction } from "antd-mobile";
import React, { useContext } from "react";
import { NavigationContext } from "../contexts/navigation";
import { useUpdateWorkOrderService } from "../data/mutations";
import { classes } from "../util/classes";
import { toWorkWeeks } from "../util/datetime";
import "./CustomerItem.less";

const Item = List.Item;
const Brief = Item.Brief;

function CustomerItem({ customer, order, service }) {
	const nav = useContext(NavigationContext);
	const mutation = useUpdateWorkOrderService();

	const hasUnpayedInvoice = false; //customer.invoices.some((i) => !i.payed);
	return (
		<SwipeAction
			style={{ backgroundColor: "gray" }}
			autoClose
			left={[
				{
					text: " ",
					className: "customer-add-todo",
					style: { backgroundColor: "#108ee9", color: "white" },
					onPress() {
						if (!service?.isEnrolled)
							mutation.mutateAsync({
								customer: customer.id,
								order: order.id,
								...service,
								isEnrolled: true,
							});
					},
				},
				{
					text: " ",
					className: "customer-remove-todo",
					style: { backgroundColor: "#f4333c", color: "white" },
					onPress() {
						if (service?.isEnrolled)
							mutation.mutateAsync({
								...service,
								isEnrolled: false,
							});
					},
				},
			]}
			onOpen={() => console.log("global open")}
			onClose={() => console.log("global close")}
		>
			<Item
				className={classes(
					"customer-item",
					service?.isDenied && "never"
				)}
				arrow="horizontal"
				multipleLine
				onClick={() => {
					nav.push(`/order/${order.id}/customer/${customer.id}`);
				}}
				platform="android"
				thumb={
					service && service.completedAt ? (
						<Icon type="check-circle" color="#1b7a47" />
					) : // <CheckCircleOutlined style={{ color: "#1b7a47" }} />
					service && service.isEnrolled ? (
						<ScissorOutlined
							style={{
								color: service.isScheduled
									? "#1b7a47"
									: "#108ee9",
							}}
						/>
					) : hasUnpayedInvoice ? (
						<EuroCircleOutlined style={{ color: "#f4333c" }} />
					) : service && service.isDenied ? (
						<Icon type="cross-circle-o" color="#f4333c" />
					) : service && service.isDue ? (
						<QuestionCircleFilled style={{ color: "#f4663c" }} />
					) : (
						<ClockCircleOutlined style={{ color: "#bbb" }} />
					)
				}
			>
				{customer.serviceInterval > 0 && (
					<div
						style={{
							float: "right",
							fontSize: ".8em",
							margin: " 4px 5px 2px",
						}}
					>
						<HistoryOutlined />
						<span style={{ marginLeft: 4 }}>
							{toWorkWeeks(customer.serviceInterval)}
						</span>
					</div>
				)}
				{customer.attributes.Besbehand && (
					<div
						style={{
							float: "right",
							fontSize: ".9em",
							margin: " 3px 5px 2px",
						}}
					>
						<InfoCircleTwoTone />
					</div>
				)}
				{customer.title} {customer.lastName}, {customer.firstName}
				{service &&
					(service.isDenied ? (
						<Brief>{customer.attributes.Besbehand}</Brief>
					) : (
						<Brief>
							{service.items?.map((service) => (
								<Badge
									key={service.sku}
									text={service.sku}
									className="service-tag"
								/>
							))}
						</Brief>
					))}
			</Item>
		</SwipeAction>
	);
}

export default CustomerItem;
