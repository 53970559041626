import React from "react";

export const NavigationContext = React.createContext({
	canGoBack: false,
	push(url) {},
	goBack() {},
	goToOrder(order) {},
	state: undefined,
	setState(state) {},

	setSaveAction(key, callbacks) {},
	setSaveSubmitting(isSubmitting) {},

	searchQuery: "",
	setSearchVisible(visible) {},
	clearSearch() {},
});
