import Dexie from "dexie";
import log from "loglevel";
import { OutboxModel, queryClient } from "./database";

export type OutboxHandler<T> = (data: T) => Promise<any>;

export class Outbox {
	private syncPromise?: Promise<any>;
	private handlers = new Map<OutboxModel["table"], OutboxHandler<any>>();

	constructor(
		private db: Dexie,
		private outbox: Dexie.Table<OutboxModel, string>
	) {}

	async add(item: Omit<OutboxModel, "id">) {
		const obid = await this.outbox.add(item as OutboxModel);
		log.info("[outbox] add id:", obid, item);
		queryClient.invalidateQueries("outbox");
		return obid;
	}

	handler<T>(table: OutboxModel["table"], handler: OutboxHandler<T>) {
		this.handlers.set(table, handler);
		return this;
	}

	sync() {
		log.info("[outbox] sync requested");
		if (this.syncPromise) return this.syncPromise;

		log.info("[outbox] starting sync");
		this.syncPromise = this.syncInternal();

		this.syncPromise
			.then(() => this.onSyncCompleted())
			.catch((err) => this.onSyncFailed(err));

		return this.syncPromise;
	}

	private onSyncCompleted() {
		this.syncPromise = undefined;
		log.info("[outbox] sync completed");
	}

	private onSyncFailed(err: Error) {
		this.syncPromise = undefined;
		log.error("[outbox] sync failed", err);
	}

	private async syncInternal() {
		log.info("[outbox] syncing");

		let item: OutboxModel | undefined;

		// Fetch next item from outbox
		while ((item = await this.outbox.orderBy("id").first())) {
			console.log("[outbox] sync item:", item);
			const handler = this.handlers.get(item.table);

			try {
				if (handler) {
					log.info("[outbox] sync item handling", handler);
					await handler(item.data);
					log.info("[outbox] sync item completed", item);
				} else {
					log.warn("[outbox] sync skipping item", item);
				}
				await this.outbox.delete(item.id as any);
			} catch (err) {
				log.info("[outbox] sync item failed", item, err);
				await this.outbox.update(item, {
					lastFailedAt: new Date(),
					error: String(err),
				});
				break;
			}
		}

		queryClient.invalidateQueries("outbox");
	}

	public async getUnsyncedItems(): Promise<number> {
		return await this.outbox.count();
	}
}
