import { Grid, Icon, InputItem, List, Modal } from "antd-mobile";
import { indexBy } from "ramda";
import React, { useState } from "react";
import { useOfferGrid } from "../hooks/offerGrid";
import { classes } from "../util/classes";
import { currencyFormat } from "../util/currency";
import { ServiceItem } from "./ServiceItem";

const Item = List.Item;

export function ServiceOffer({ offer, planned, selected, onClick }) {
	return (
		<div
			className={classes(
				"service-offer",
				selected && "selected",
				planned && (planned.interval === -1 ? "never" : "planned")
			)}
			onClick={onClick}
		>
			<span className="service-offer-sku">{offer.sku}</span>
			<span className="service-offer-price">
				{currencyFormat.format(offer.price)}
			</span>
		</div>
	);
}

export function ServiceItemList({ items, offers, customer, onChange }) {
	const [itemCounter, setItemCounter] = useState(0);
	const [showOfferSelectionModal, setShowOfferSelectionModal] =
		useState(false);
	const [editSelectedItem, setEditSelectedItem] = useState(null);

	// Arrange offer grid according to sort index
	const serviceOffers = useOfferGrid(offers, customer);

	// Map pricelist offers by sku
	const offersBySku = indexBy((p) => p.sku, offers);

	// Map selected services by sku
	const offerMap = items.reduce((m, s) => ({ ...m, [s.sku]: true }), {});

	const toggleSelectedOffer = (offer) => {
		console.log("click offer", offer);

		const idx = items.findIndex((s) => s.sku === offer.sku);
		if (idx < 0) {
			onChange([
				...items,
				{
					id: itemCounter,
					sku: offer.sku,
					description: offer.description,
					// originalPrice: offer.price,
					price: offer.price,
				},
			]);
			setItemCounter(itemCounter + 1);
		} else {
			onChange(items.filter((s) => s.sku !== offer.sku));
		}
	};

	const deleteItem = (service) => {
		onChange(items.filter((s) => s.sku !== service.sku));
	};

	const editItem = (service) => {
		const originalPrice = offersBySku[service.sku].price;
		setEditSelectedItem({ ...service, originalPrice });
	};

	const updateServiceItem = (service) => {
		console.log("updateServiceItem:", service);
		onChange(
			items.map((item) => {
				if (item.sku !== service.sku) return item;

				const originalPrice = offersBySku[service.sku].price;
				if (service.price !== originalPrice)
					return { ...service, originalPrice };
				else return { ...service, originalPrice: undefined };
			})
		);
	};

	function onPriceChange(newPrice) {
		setEditSelectedItem({ ...editSelectedItem, price: +newPrice });
	}

	return (
		<>
			<List renderHeader={() => "Erbrachte Leistungen"}>
				<Item
					arrow="down"
					platform="android"
					onClick={() => setShowOfferSelectionModal(true)}
					thumb={<Icon type="plus" />}
				>
					Leistung hinzufügen
				</Item>
				{items.map((service) => (
					<ServiceItem
						key={service.sku}
						service={service}
						onDelete={() => deleteItem(service)}
						onClick={() => editItem(service)}
					/>
				))}
			</List>

			<Modal
				visible={showOfferSelectionModal}
				transparent
				popup
				animationType="slide-up"
				onClose={(e) => {
					console.log("modal close:", e);
					setShowOfferSelectionModal(false);
				}}
				title="Leistungen auswählen"
				footer={[
					{
						text: "Hinzufügen",
						onPress: () => {
							console.log("ok");
							setShowOfferSelectionModal(false);
						},
					},
				]}
			>
				<Grid
					className="service-grid"
					data={serviceOffers}
					isCarousel
					columnNum={6}
					carouselMaxRow={8}
					renderItem={({ offer, planned }) =>
						offer && (
							<ServiceOffer
								offer={offer}
								planned={planned}
								onClick={() => toggleSelectedOffer(offer)}
								selected={offerMap[offer.sku]}
							/>
						)
					}
				/>
			</Modal>

			{editSelectedItem && (
				<Modal
					visible={true}
					transparent
					onClose={(e) => {
						console.log("modal close:", e);
						setEditSelectedItem(null);
					}}
					title="Preis anpassen"
					footer={[
						{
							text: "Anpassen",
							onPress: () => {
								updateServiceItem(editSelectedItem);
								setEditSelectedItem(null);
							},
						},
					]}
				>
					<InputItem
						type="money"
						editable={false}
						value={editSelectedItem.originalPrice}
						extra="€"
					>
						Ursprüngl.:
					</InputItem>
					<InputItem
						type="money"
						placeholder={editSelectedItem.price}
						onChange={onPriceChange}
						onVirtualKeyboardConfirm={() => {
							updateServiceItem(editSelectedItem);
							setEditSelectedItem(null);
						}}
						ref={(elm) => elm && elm.focus()}
						extra="€"
					>
						Neu:
					</InputItem>
				</Modal>
			)}
		</>
	);
}
