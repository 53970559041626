export const currencyFormat = new Intl.NumberFormat("de-DE", {
	style: "currency",
	currency: "EUR",
});

// Format as local currency without symbol
export const currencyNumberFormat = {
	format: (value) =>
		value?.toLocaleString("de-DE", {
			...currencyFormat.resolvedOptions(),
			style: "decimal",
		}),
};

// Calculates the total price of items (2 decimal digits)
export const totalPrice = (items) =>
	Number(items.reduce((total, { price }) => total + price, 0).toFixed(2));
