import {
	CarryOutOutlined,
	HomeOutlined,
	ScissorOutlined,
} from "@ant-design/icons";
import {
	Badge,
	Card,
	Icon,
	Result,
	Steps,
	WhiteSpace,
	WingBlank,
} from "antd-mobile";
import { descend, sortWith } from "ramda";
import { useContext } from "react";
import { AuthContext } from "../contexts/auth";
import { NavigationContext } from "../contexts/navigation";
import { useFullOrderSync, useOrders, useWorkplace } from "../hooks/data";
import { getOrderTimespan, weekDateFormat } from "../util/datetime";
import "./OrderOverview.less";
import { PageLoading } from "./PageLoading";

export const OrderOverview = () => {
	const auth = useContext(AuthContext);
	const { isLoading, data: orders } = useOrders();
	useFullOrderSync();

	if (isLoading) {
		return <PageLoading />;
	}

	const sortedOrders = sortWith(
		[descend((o) => Date.parse(o.scheduledAt))],
		orders
	);

	const [minToday, maxToday] = getOrderTimespan(new Date());
	const todaysOrders = sortedOrders.filter((o) => {
		const dt = Date.parse(o.scheduledAt);
		return dt >= minToday && dt < maxToday;
	});
	const otherOrders = sortedOrders.filter((o) => {
		const dt = Date.parse(o.scheduledAt);
		return dt < minToday || dt >= maxToday;
	});

	return (
		<WingBlank size="lg">
			<WhiteSpace size="md" />
			<h2 className="welcome-title">Hallo, {auth.userInfo.firstName}!</h2>
			{todaysOrders.length ? (
				<>
					<h4 className="welcome-subtitle">
						Dies sind deine heutigen Aufträge:
					</h4>
					{todaysOrders.map((order) => (
						<OrderCard key={order.id} order={order} isToday />
					))}
				</>
			) : (
				<Result
					className="result-no-bg"
					img={
						<Icon
							type="check-circle"
							className="result-large-icon"
							style={{ fill: "#1F90E6" }}
						/>
					}
					title="Für heute gibt es keine Aufträge"
				/>
			)}
			{otherOrders.length > 0 && (
				<>
					<h4 className="other-others-title">Ausstehende Termine:</h4>
					{otherOrders.map((order) => (
						<OrderCard key={order.id} order={order} />
					))}
				</>
			)}
		</WingBlank>
	);
};

export const OrderCard = ({ order, isToday }) => {
	const nav = useContext(NavigationContext);
	const { data: workplace } = useWorkplace(order.workplace);

	if (!workplace) return null;

	const workDate = Date.parse(order.scheduledAt);
	const nowDate = new Date();
	// order.billedAt = nowDate;
	const currentState = order.billedAt
		? 4
		: order.completedAt
		? 3
		: workDate < nowDate
		? 1
		: 0;

	return (
		<Card
			className="order-card"
			onClick={() => {
				nav.push(`/order/${order.id}`);
			}}
		>
			<Card.Header
				title={
					<div
						className={`order-workplace-title ${
							isToday ? "primary" : ""
						}`}
					>
						<span>{workplace.name}</span>
						<div style={{ display: "inline-block" }}>
							{order.units
								.filter((unit) => unit) // filter out null
								.map((unit) => (
									<Badge
										key={unit}
										text={unit}
										className="unit-tag"
									/>
								))}
						</div>
					</div>
				}
				// thumb="https://gw.alipayobjects.com/zos/rmsportal/MRhHctKOineMbKAZslML.jpg"
				thumb={<HomeOutlined />}
				extra={
					<span>
						{isToday ? (
							<Badge
								text="Heute"
								style={{
									fontSize: 16,
									padding: "4px 8px 2px 8px",
								}}
							/>
						) : (
							weekDateFormat.format(Date.parse(order.scheduledAt))
						)}
					</span>
				}
			/>
			<Card.Body>
				<WhiteSpace size="md" />
				<Steps direction="horizontal" current={currentState}>
					<Steps.Step
						title="Vorbereitung"
						icon={<CarryOutOutlined />}
					/>
					<Steps.Step
						title="Bearbeitung"
						icon={<ScissorOutlined />}
					/>
					<Steps.Step title="Abrechnung" />
				</Steps>
				<WhiteSpace size="md" />
			</Card.Body>
			<Card.Footer
				// content={
				// 	workplace.contactName && (
				// 		<div>
				// 			<UserOutlined />
				// 			<span className="contact-name">
				// 				{workplace.contactName}
				// 			</span>
				// 		</div>
				// 	)
				// }
				extra={`Nr. ${order.scheduleNo}`}
			/>
		</Card>
	);
};
