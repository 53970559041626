import log from "loglevel";
import React, { useContext, useEffect, useState } from "react";
import { AuthControllerService, UserControllerService } from "../client";
import { WorkerRegistrationContext } from "./worker";

export const AuthContext = React.createContext({
	isLoggedIn: false,
	userInfo: null,
	login(user, pass) {},
	logout() {},
});

const sessionKey = "mfs_user";

export const AuthenticatedContext = ({ children }) => {
	console.log("auth context");
	const worker = useContext(WorkerRegistrationContext);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const [loading, setLoading] = useState(true);

	function handleLogin(userInfo) {
		localStorage.setItem(sessionKey, JSON.stringify(userInfo));
		setUserInfo(userInfo);
		setIsLoggedIn(true);
	}

	function handleLogout() {
		localStorage.removeItem(sessionKey);
		setIsLoggedIn(false);
		setUserInfo(null);
	}

	useEffect(() => {
		// Restore user session state
		const userLogin = localStorage.getItem(sessionKey);

		if (userLogin) {
			setUserInfo(JSON.parse(userLogin));
			setIsLoggedIn(true);
		}

		const checkSessionToken = async () => {
			try {
				const user = await UserControllerService.getUserAuth();
				log.info("User session validated:", user);

				if (!user || !user.employee)
					throw new Error("Missing user employee");

				localStorage.setItem(sessionKey, JSON.stringify(user.employee));

				await worker.forceSync();
				log.info("Worker sync triggered");
			} catch (err) {
				log.error("token refresh failed:", err.status, err);
				if (err.status === 401) {
					handleLogout();
				}
			}
		};

		setLoading(false);
		checkSessionToken();
	}, [worker]);

	const context = {
		isLoggedIn,
		userInfo,

		async login(username, password) {
			try {
				const authResult = await AuthControllerService.login({
					username,
					password,
				});
				handleLogin(authResult);
				return true;
			} catch (err) {
				console.error("login failed:", err);
				handleLogout();
				return false;
			}
		},

		logout() {
			AuthControllerService.logout().catch((err) =>
				console.error("logout failed:", err)
			);
			handleLogout();
		},
	};

	console.log("loading", { loading, isLoggedIn, userInfo });

	return (
		<AuthContext.Provider value={context}>
			{loading ? <span>Loading ...</span> : children}
		</AuthContext.Provider>
	);
};
