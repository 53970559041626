import {
	CustomerControllerService,
	CustomerModel,
	ServiceModel,
	ServiceModelSubmission,
	WorkLogModel,
	WorkOrderControllerService,
	WorkOrderModel,
} from "../client";
import { useOutboxMutation } from "../hooks/data";
import { queryClient } from "./database";

export const updateWorkOrderService = async (
	service: ServiceModelSubmission
) => {
	const orderId =
		(service.order as WorkOrderModel).id || (service.order as string);
	const customerId =
		(service.customer as CustomerModel).id || (service.customer as string);

	const result = await WorkOrderControllerService.updateWorkOrderService(
		orderId,
		customerId,
		service
	);

	// Invalidate any queries for services of the order and customer
	queryClient.invalidateQueries(["services", orderId], undefined, {});
	queryClient.invalidateQueries(["services", customerId], undefined, {});

	return result;
};

export const useUpdateWorkOrderService = () =>
	useOutboxMutation<ServiceModel>("services");
//	useDataMutation<ServiceModel>("services", updateWorkOrderService);

export const submitCustomerData = async (customer: CustomerModel) => {
	if (!customer.id) throw new Error("Missing customer id");

	const result = await CustomerControllerService.updateCustomer(
		customer.id,
		customer
	);

	return result;
};

export const useSubmitCustomerData = () =>
	useOutboxMutation<CustomerModel>("customers");
// 	useDataMutation<CustomerModel>("customers", submitCustomerData);

export const submitLogMessage = async (msg: WorkLogModel) => {
	const orderId = (msg.order as WorkOrderModel).id || (msg.order as string);

	if (!orderId) throw new Error("Missing order");
	if (!msg.id) throw new Error("Missing message id");

	const result = await WorkOrderControllerService.updateWorkOrderMessage(
		orderId,
		msg.id,
		msg
	);

	return result;
};

export const useSubmitLogMessage = () =>
	useOutboxMutation<WorkLogModel>("workLog");
//	useDataMutation<WorkLogModel>("workLog", submitLogMessage);
