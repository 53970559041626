import { LocaleProvider } from "antd-mobile";
import enUS from "antd-mobile/lib/locale-provider/en_US";
import log from "loglevel";
import React from "react";
import ReactDOM from "react-dom";
import "./api-client";
import App from "./App";
import { OfflineModeProvider } from "./contexts/offline";
import { WorkerRegistration } from "./contexts/worker";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { IndexedDbContext } from "./contexts/database";

console.log("running in %s mode", process.env.NODE_ENV, process.env);
log.enableAll();

const locale = {
	...enUS,
	InputItem: {
		...enUS.InputItem,
		confirmLabel: "OK",
	},
};

const workerOptions = {};

ReactDOM.render(
	// <React.StrictMode>
	<WorkerRegistration workerOptions={workerOptions}>
		<IndexedDbContext>
			<OfflineModeProvider>
				<LocaleProvider locale={locale}>
					<App />
				</LocaleProvider>
			</OfflineModeProvider>
		</IndexedDbContext>
	</WorkerRegistration>,
	// </React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(workerOptions);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
