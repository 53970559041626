import {
	CalendarOutlined,
	QuestionCircleFilled,
	ScissorOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Badge, Icon, List, Modal } from "antd-mobile";
import React, { useState } from "react";
import { useEmployee, useOrder } from "../hooks/data";
import { classes } from "../util/classes";
import {
	currencyFormat,
	currencyNumberFormat,
	totalPrice,
} from "../util/currency";
import { dateTimeFormat, shorterDateFormat } from "../util/datetime";
import "./CustomerServiceItem.less";
import { PaymentOptionPicker } from "./PaymentOptionPicker";
import { PriceDisplay } from "./ServiceItem";

const Item = List.Item;

function CustomerServiceModal({ service, onClose }) {
	const { data: order } = useOrder(service.order);
	const { data: employee } = useEmployee(order?.employee);
	const priceTotal = totalPrice(service.items);

	if (!order || !employee) return null;

	return (
		<Modal
			className="customer-service-modal"
			transparent
			visible
			onClose={onClose}
			title={
				service.completedAt
					? `Behandlung — ${dateTimeFormat.format(
							new Date(service.completedAt)
					  )}`
					: "Vorgeschlagene Leistungen"
			}
			footer={[
				{
					text: "Schließen",
					onPress: onClose,
				},
			]}
		>
			{service.payment && (
				<PaymentOptionPicker
					title="Zahlungsart"
					value={service.payment}
					editable={false}
				/>
			)}
			{service.completedAt && (
				<Item thumb={<UserOutlined />}>
					{employee.lastName}, {employee.firstName}
				</Item>
			)}
			{service.items.map((item, i) => (
				<Item
					key={i}
					extra={
						item.price ? (
							<PriceDisplay
								price={item.price}
								originalPrice={item.originalPrice}
							/>
						) : undefined
					}
				>
					{item.description}
				</Item>
			))}

			<Item
				className="service-total-price"
				extra={currencyFormat.format(priceTotal)}
			>
				Gesamt:
			</Item>
			{service.payment?.amountReceived && (
				<Item
					extra={currencyFormat.format(
						service.payment.amountReceived
					)}
				>
					Gegeben:
				</Item>
			)}
		</Modal>
	);
}

function CustomerServiceItem({ service, employee }) {
	const [detailsVisible, setDetailsVisible] = useState(false);

	// const extra = service.completedAt
	// 	? (employee ? `${employee.shortName}｜` : "") +
	// 	  new Date(service.completedAt).toLocaleDateString("de-DE", {
	// 			year: "numeric",
	// 			month: "2-digit",
	// 			day: "2-digit",
	// 	  })
	// 	: service.isDenied
	// 	? "Nicht behandeln!"
	// 	: "Empfehlung";

	const total = totalPrice(service.items);

	const statusColor = service.paymentReceived
		? service.payment.amountReceived >= total
			? "#1b7a47"
			: "#ff8f00"
		: "#f4333c";

	const extra = service.completedAt ? (
		<div style={{ lineHeight: 1 }}>
			<div
				style={{
					color: statusColor,
				}}
			>
				{service.payment ? (
					<Badge
						text={service.payment.type}
						style={{
							background: statusColor,
							marginRight: 6,
							marginBottom: 1,
							fontSize: ".7em",
							height: "1.4em",
							padding: "0 4px",
						}}
					/>
				) : null}
				{service.payment?.amountReceived < total &&
					currencyNumberFormat.format(
						service.payment.amountReceived
					) + " / "}
				{currencyFormat.format(total)}
			</div>
			<small>
				{employee && (
					<span>
						<UserOutlined /> {employee.shortName}
					</span>
				)}
				<span style={{ marginLeft: 8 }}>
					<CalendarOutlined />{" "}
					{shorterDateFormat.format(new Date(service.completedAt))}
				</span>
			</small>
		</div>
	) : service.isDenied ? (
		"Nicht behandeln!"
	) : (
		"Empfehlung"
	);

	// const totalPrice = service.items?.reduce(
	// 	(total, item) => total + item.price,
	// 	0
	// );

	return (
		<>
			<Item
				className={classes(
					"customer-service-item",
					service.isDenied && "am-list-item-error"
				)}
				thumb={
					service.billedAt ? (
						<Icon type="check-circle-o" color="#1b7a47" />
					) : service.completedAt ? (
						<Icon type="check" color="#1b7a47" />
					) : service.isEnrolled ? (
						<ScissorOutlined
							style={{
								color: service.isScheduled
									? "#1b7a47"
									: "#108ee9",
							}}
						/>
					) : service.isDenied ? (
						<Icon type="cross-circle-o" color="#f4333c" />
					) : service && service.isDue ? (
						<QuestionCircleFilled style={{ color: "#f4663c" }} />
					) : (
						<ScissorOutlined style={{ color: "#a4a9b0" }} />
					)
				}
				extra={extra}
				onClick={() => setDetailsVisible(true)}
			>
				{service.items?.length
					? service.items?.map((item) => (
							<Badge
								key={item.sku}
								text={item.sku}
								className="service-tag"
							/>
					  ))
					: service.isDenied
					? "Nicht behandeln!"
					: service.completedAt
					? "Erledigt"
					: service.isEnrolled
					? "Behandlung angemeldet"
					: "Nicht geplant"}
			</Item>
			{detailsVisible && (
				<CustomerServiceModal
					service={service}
					onClose={() => setDetailsVisible(false)}
				/>
			)}
		</>
	);
}

export default CustomerServiceItem;
