import {
	CommentOutlined,
	EuroOutlined,
	ScissorOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Flex, List, Result, TabBar } from "antd-mobile";
import { useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { NavigationContext } from "../contexts/navigation";
import { useOrder, useWorkplace, useWorkplaceLogs } from "../hooks/data";
import { weekDateFormat } from "../util/datetime";
import { CompletedWorkList } from "./CompletedWorkList";
import { MessageLog } from "./MessageLog";
import "./OrderDetails.less";
import { PageLoading } from "./PageLoading";
import { WorkOrderList } from "./WorkOrderList";

export const OrderDetails = ({
	match: {
		params: { orderId },
	},
}) => {
	const { isLoading: isLoadingO, data: order } = useOrder(orderId);
	const { isLoading: isLoadingW, data: workplace } = useWorkplace(
		order?.workplace
	);
	const { isLoading: isLoadingM, data: logs } = useWorkplaceLogs(
		order?.workplace || ""
	);
	// const [selectedTab, setSelectedTab] = useState("customers");
	const nav = useContext(NavigationContext);

	const { path, url } = useRouteMatch();

	const matchCustomers = useRouteMatch({ path: `${path}`, exact: true });
	const matchInvoices = useRouteMatch({
		path: `${path}/invoices`,
		exact: true,
	});
	const matchMessages = useRouteMatch({
		path: `${path}/messages`,
		exact: true,
	});
	const matchCompleted = useRouteMatch({
		path: `${path}/completed`,
		exact: true,
	});

	if (isLoadingO || isLoadingW || isLoadingM) {
		return <PageLoading />;
	}

	if (!order || !workplace) {
		return (
			<List>
				<List.Item>Not found</List.Item>
			</List>
		);
	}

	const hasNewMessages = logs.some((m) => m.order === order?.id);

	return (
		<>
			<List
				renderHeader={
					<Flex className="order-heading">
						<span>{workplace.name}</span>
						<span>
							{weekDateFormat.format(
								Date.parse(order.scheduledAt)
							)}
						</span>
					</Flex>
				}
			></List>
			<div
				style={{
					height: "calc(100vh - 85px)",
				}}
			>
				<TabBar
					unselectedTintColor="#949494"
					tintColor="#33A3F4"
					barTintColor="white"
					tabBarPosition="bottom"
					prerenderingSiblingsNumber={0}
				>
					<TabBar.Item
						title="Kunden"
						key="customers"
						icon={<UserOutlined />}
						selectedIcon={<UserOutlined />}
						selected={matchCustomers}
						onPress={() => nav.navigateTo(url)}
					>
						<WorkOrderList key={order.id} order={order} />
					</TabBar.Item>
					<TabBar.Item
						title="Kommentare"
						key="messages"
						icon={<CommentOutlined />}
						selectedIcon={<CommentOutlined />}
						selected={matchMessages}
						onPress={() => nav.navigateTo(`${url}/messages`)}
						dot={hasNewMessages}
					>
						<MessageLog key={order.id} order={order} />
					</TabBar.Item>
					<TabBar.Item
						title="Rechnungen"
						key="invoices"
						icon={<EuroOutlined />}
						selectedIcon={<EuroOutlined />}
						selected={matchInvoices}
						onPress={() => nav.navigateTo(`${url}/invoices`)}
					>
						<Result
							title="Noch nicht verfügbar."
							style={{ height: "100%" }}
						/>
					</TabBar.Item>
					<TabBar.Item
						title="Erledigt"
						key="completed"
						icon={<ScissorOutlined />}
						selectedIcon={<ScissorOutlined />}
						selected={matchCompleted}
						onPress={() => nav.navigateTo(`${url}/completed`)}
					>
						<CompletedWorkList key={order.id} order={order} />
					</TabBar.Item>
				</TabBar>
			</div>
		</>
	);
};
