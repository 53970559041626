import { LoadingOutlined } from "@ant-design/icons";
import { Icon, NavBar, NoticeBar, SearchBar } from "antd-mobile";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { NavigationContext } from "../contexts/navigation";
import { WorkerRegistrationContext } from "../contexts/worker";
import logo from "../images/brand.svg";
import { Menu } from "./Menu";
import "./Navigation.less";
import { OfflineModeIndicator } from "./OfflineModeIndicator";
import { SyncStatusIndicator } from "./SyncStatusIndicator";

function Navigation({ children }) {
	const serviceWorker = useContext(WorkerRegistrationContext);
	const history = useHistory();
	const location = useLocation();
	const [hasUnsavedChanged, setHasUnsavedChanges] = useState(false);
	const [saveActions, setSaveActions] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [searchVisible, setSearchVisible] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");

	const navContext = {
		get canGoBack() {
			return location.pathname !== "/";
		},
		push(target) {
			if (location.pathname === target) return;
			history.push(target);
		},
		goBack() {
			history.goBack();
		},
		navigateTo(target) {
			console.log("navigate", history);
			history.replace(target);
		},
		goToOrder(order) {
			history.go(-2);
		},

		state: location.state,
		setState(state) {
			console.log("setState", state, history);
			history.replace(location.pathname, { ...location.state, ...state });
		},

		setSaveAction(key, callbacks) {
			console.log("setSaveAction", key, callbacks);
			const newActions = { ...saveActions, [key]: callbacks };
			if (!callbacks) {
				delete newActions[key];
			}
			setHasUnsavedChanges(Object.keys(newActions).length > 0);
			setSaveActions(newActions);
		},
		async saveAll() {
			try {
				for (const key in saveActions) {
					await saveActions[key].onSave();
				}
				setSaveActions({});
				setHasUnsavedChanges(false);
			} catch (err) {
				setIsSubmitting(false);
			}
		},
		setSaveSubmitting(isSubmitting) {
			setIsSubmitting(!!isSubmitting);
		},

		searchQuery,
		setSearchVisible,
		clearSearch() {
			setSearchQuery("");
		},
	};

	const onUpdateClick = () => {
		serviceWorker.skipWaiting();
	};

	const onGoBackClick = () => {
		if (!navContext.canGoBack) return;

		// if (!hasUnsavedChanged)
		return navContext.goBack();

		// Modal.alert(
		// 	"Änderungen speichern?",
		// 	<>
		// 		Einige Ihrer Änderungen wurden noch nicht gespeichert!
		// 		<br />
		// 		Wie möchten Sie fortfahren?
		// 	</>,
		// 	[
		// 		{
		// 			text: "Verwerfen",
		// 			style: "destructive",
		// 			onPress: () => navContext.goBack(),
		// 		},
		// 		{
		// 			text: "Speichern",
		// 			onPress: async () => {
		// 				await navContext.saveAll();
		// 				navContext.goBack();
		// 			},
		// 		},
		// 	]
		// );
	};

	return (
		<NavigationContext.Provider value={navContext}>
			<NavBar
				className="navigation"
				mode="dark"
				icon={navContext.canGoBack && <Icon type="left" />}
				onLeftClick={onGoBackClick}
				rightContent={[
					<SyncStatusIndicator key="sync-status" />,
					!isSubmitting && !hasUnsavedChanged && (
						<OfflineModeIndicator key="offline-mode" />
					),
					isSubmitting ? (
						<LoadingOutlined key="save-submitting" />
					) : hasUnsavedChanged ? (
						// <Icon
						// 	key="save"
						// 	type="check-circle-o"
						// 	onClick={() => saveAction.onSave()}
						// />
						<span
							key="save-all"
							className="nav-save-all"
							onClick={() => navContext.saveAll()}
						>
							Speichern
						</span>
					) : (
						<Menu key="main-menu" navContext={navContext} />
					),
				]}
			>
				{searchVisible ? (
					<SearchBar
						placeholder="Suchen"
						showCancelButton={true}
						cancelText="Abbrechen"
						value={searchQuery}
						onChange={setSearchQuery}
					/>
				) : (
					<img src={logo} alt="Logo" className="brand-logo" />
				)}
			</NavBar>
			<div style={{ marginTop: 45 }}></div>
			{serviceWorker.updateAvailable && (
				<NoticeBar
					mode="link"
					action={<span>Aktualisieren</span>}
					onClick={onUpdateClick}
				>
					Ein Software-Update ist verfügbar.
				</NoticeBar>
			)}
			<div key="2" children={children}></div>
		</NavigationContext.Provider>
	);
}

export default Navigation;
