/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PriceListModel } from '../models/PriceListModel';
import type { PriceListModelCreation } from '../models/PriceListModelCreation';
import type { PriceListModelPriceList } from '../models/PriceListModelPriceList';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PriceListControllerService {

    /**
     * @returns PriceListModelPriceList All price lists
     * @throws ApiError
     */
    public static getPriceLists(): CancelablePromise<Array<PriceListModelPriceList>> {
        return __request({
            method: 'GET',
            path: `/pricelists`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id
     * @returns PriceListModel Success
     * @throws ApiError
     */
    public static getPriceList(
        id: string,
    ): CancelablePromise<PriceListModel> {
        return __request({
            method: 'GET',
            path: `/pricelists/${id}`,
            errors: {
                401: `Unauthorized`,
                404: `Price list not found`,
            },
        });
    }

    /**
     * @param priceListNo
     * @param requestBody
     * @returns PriceListModel Price list created
     * @throws ApiError
     */
    public static updatePriceList(
        priceListNo: number,
        requestBody?: PriceListModelCreation,
    ): CancelablePromise<PriceListModel> {
        return __request({
            method: 'PUT',
            path: `/pricelists/${priceListNo}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}