/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeModel } from '../models/EmployeeModel';
import type { EmployeeModelCreation } from '../models/EmployeeModelCreation';
import type { UserModel } from '../models/UserModel';
import type { UserModelRegistration } from '../models/UserModelRegistration';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class EmployeeControllerService {

    /**
     * @returns EmployeeModel All employees
     * @throws ApiError
     */
    public static getEmployees(): CancelablePromise<Array<EmployeeModel>> {
        return __request({
            method: 'GET',
            path: `/employees`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateEmployees(
        requestBody?: Array<EmployeeModelCreation>,
    ): CancelablePromise<void> {
        return __request({
            method: 'PUT',
            path: `/employees`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param firstName
     * @param lastName
     * @returns EmployeeModel Employee found
     * @throws ApiError
     */
    public static getEmployeeByName(
        firstName?: string,
        lastName?: string,
    ): CancelablePromise<EmployeeModel> {
        return __request({
            method: 'GET',
            path: `/employees/byName`,
            query: {
                'firstName': firstName,
                'lastName': lastName,
            },
            errors: {
                401: `Unauthorized`,
                404: `Employee not found`,
            },
        });
    }

    /**
     * @param employeeNo
     * @param requestBody
     * @returns EmployeeModel Employee created
     * @throws ApiError
     */
    public static updateEmployeeByNo(
        employeeNo?: number,
        requestBody?: EmployeeModelCreation,
    ): CancelablePromise<EmployeeModel> {
        return __request({
            method: 'PUT',
            path: `/employees/byNo`,
            query: {
                'employeeNo': employeeNo,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param employeeId
     * @returns EmployeeModel Employee found
     * @throws ApiError
     */
    public static getEmployee(
        employeeId: string,
    ): CancelablePromise<EmployeeModel> {
        return __request({
            method: 'GET',
            path: `/employees/${employeeId}`,
            errors: {
                401: `Unauthorized`,
                404: `Employee not found`,
            },
        });
    }

    /**
     * @param employeeId
     * @param requestBody
     * @returns EmployeeModel Employee found
     * @throws ApiError
     */
    public static updateEmployee(
        employeeId: string,
        requestBody?: EmployeeModel,
    ): CancelablePromise<EmployeeModel> {
        return __request({
            method: 'PUT',
            path: `/employees/${employeeId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param employeeId
     * @param requestBody
     * @returns UserModel User created
     * @throws ApiError
     */
    public static setEmployeeLogin(
        employeeId: string,
        requestBody?: UserModelRegistration,
    ): CancelablePromise<UserModel> {
        return __request({
            method: 'PUT',
            path: `/employees/${employeeId}/user`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Employee not found`,
            },
        });
    }

}