import { FieldTimeOutlined } from "@ant-design/icons";
import { Grid, Icon, List, Modal, Stepper } from "antd-mobile";
import { indexBy, prop, values } from "ramda";
import React, { useCallback, useState } from "react";
import { usePriceList, useWorkplace } from "../hooks/data";
import { useOfferGrid } from "../hooks/offerGrid";
import { toWorkMonths, toWorkWeeks } from "../util/datetime";

export function PlannedServiceItem({ interval, offer, onClick }) {
	return (
		<List.Item
			extra={
				interval === -1
					? "nie vorschlagen"
					: interval < 7
					? `jede Woche`
					: interval >= 90
					? `alle ${toWorkMonths(interval)} Monate`
					: `alle ${toWorkWeeks(interval)} Wochen`
			}
			onClick={onClick}
		>
			{offer.description}
		</List.Item>
		// <Item
		// 	wrap
		// 	extra={
		// 		<Stepper
		// 			style={{ width: "100%", minWidth: "100px" }}
		// 			showNumber
		// 			min={1}
		// 			value={interval}
		// 			unit="Tage"
		// 		/>
		// 	}
		// >
		// 	{offer.description}
		// </Item>
		// <Picker
		// 	// extra={`alle ${interval} Tage`}
		// 	value={[interval]}
		// 	data={intervals}
		// 	okText="Auswählen"
		// 	dismissText="Schließen"
		// 	cols={1}
		// 	title={offer.description}
		// 	onOk={(e) => console.log("ok", e)}
		// 	onDismiss={(e) => console.log("dismiss", e)}
		// >
		// 	<List.Item arrow="horizontal">{offer.description}</List.Item>
		// </Picker>
	);
}

export function PlannedServiceModal({ interval, offer, onSubmit, onCancel }) {
	const [selectedInterval, setSelectedInterval] = useState(interval);

	const suggestions = [
		{ value: 5, label: "1", unit: "Woche" },
		{ value: 12, label: "2", unit: "Wochen" },
		{ value: 19, label: "3", unit: "Wochen" },
		{ value: 26, label: "4", unit: "Wochen" },
		{ value: 33, label: "5", unit: "Wochen" },
		{ value: 40, label: "6", unit: "Wochen" },
		{ value: 54, label: "8", unit: "Wochen" },
		{ value: 90, label: "3", unit: "Monate" },
		{ value: 180, label: "6", unit: "Monate" },
	];

	return (
		<Modal
			visible={true}
			transparent
			onClose={(e) => {
				console.log("modal close:", e);
				onCancel();
			}}
			title={offer.description}
			footer={[
				{
					text: "Entfernen",
					style: "destructive",
					onPress: () => {
						onSubmit({
							sku: offer.sku,
							isPrimary: offer.isPrimary,
							interval: -1,
						});
					},
				},
				{
					text: "Anpassen",
					onPress: () => {
						onSubmit({
							sku: offer.sku,
							isPrimary: offer.isPrimary,
							interval: selectedInterval,
						});
					},
				},
			]}
			// wrapProps={{ onTouchStart: this.onWrapTouchStart }}
			afterClose={() => {
				console.log("after close");
				// alert("afterClose");
			}}
		>
			<List.Item
				className="customer-service-interval"
				extra={
					<Stepper
						style={{ width: "100%", minWidth: "100px" }}
						showNumber
						max={1000}
						min={0}
						step={7}
						value={selectedInterval}
						onChange={(v) => setSelectedInterval(v)}
					/>
				}
			>
				Anz. Tage:
			</List.Item>
			<List.Item>
				<Grid
					className="customer-service-interval-grid"
					square={false}
					data={suggestions}
					columnNum={3}
					renderItem={({ value, label, unit }) => (
						<div
							className={`service-interval ${
								selectedInterval === value ? "selected" : ""
							}`}
						>
							<span className="service-interval-label">
								{label}
							</span>
							<small className="service-interval-unit">
								{unit}
							</small>
						</div>
					)}
					onClick={({ value }) => {
						setSelectedInterval(value);
					}}
				/>
			</List.Item>
			{/* <InputItem
				type="number"
				// placeholder={editSelectedItem.price}
				// onChange={onPriceChange}
				onVirtualKeyboardConfirm={() => {
					// updateServiceItem(editSelectedItem);
					// setEditSelectedItem(null);
				}}
				ref={(elm) => elm && elm.focus()}
				extra="€"
			>
				Neu:
			</InputItem> */}
		</Modal>
	);
}

const intervalToWeeks = (interval) =>
	interval !== -1
		? interval >= 90
			? `${toWorkMonths(interval)} Mon.`
			: `${toWorkWeeks(interval)} Wo.`
		: "–";

export function ServiceOffer({ offer: { sku, interval, isPrimary }, planned }) {
	return (
		<div
			className={[
				"service-offer",
				isPrimary && "primary",
				planned && (planned.interval === -1 ? "never" : "selected"),
			].join(" ")}
		>
			<span className="service-offer-sku">{sku}</span>
			<span className="service-offer-interval">
				{planned?.interval === -1 ? (
					<Icon type="cross-circle-o" />
				) : (
					intervalToWeeks(planned?.interval || interval)
				)}
			</span>
		</div>
	);
}

function OfferSelectionModal({ customer, onSubmit, onCancel }) {
	const { data: workplace } = useWorkplace(customer.workplace);
	const { data: priceList } = usePriceList(workplace.priceList);

	const offers = priceList?.offers.filter((o) => o.isService);
	const serviceOffers = useOfferGrid(offers, customer);

	if (!workplace || !priceList) return null;

	const plannedBySku = indexBy((s) => s.sku, customer.plannedServices);

	return (
		<Modal
			visible={true}
			transparent
			popup
			animationType="slide-up"
			onClose={(e) => {
				console.log("modal close:", e);
				onCancel();
			}}
			title="Leistung einplanen"
			footer={
				[
					// {
					// 	text: "Zurücksetzen",
					// 	onPress: () => {
					// 		updateServiceItem({
					// 			...editSelectedItem,
					// 			price: editSelectedItem.originalPrice,
					// 		});
					// 		setEditSelectedItem(null);
					// 	},
					// },
					// {
					// 	text: "Anpassen",
					// 	onPress: () => {
					// 		onSubmit({
					// 			sku: offer.sku,
					// 			interval: selectedInterval,
					// 		});
					// 	},
					// },
				]
			}
		>
			<Grid
				className="service-grid"
				data={serviceOffers}
				isCarousel
				columnNum={6}
				carouselMaxRow={8}
				renderItem={({ offer }) =>
					offer ? (
						<ServiceOffer
							offer={offer}
							planned={plannedBySku[offer.sku]}
						/>
					) : null
				}
				onClick={({ offer }) => onSubmit(offer)}
			/>
			{/* <Grid
				className="customer-service-interval-grid"
				square={false}
				data={priceList.offers}
				columnNum={3}
				renderItem={({ value, label, unit }) => (
					<div
					// className={`service-interval ${
					// 	selectedInterval === value ? "selected" : ""
					// }`}
					>
						<span className="service-interval-label">{label}</span>
						<small className="service-interval-unit">{unit}</small>
					</div>
				)}
				onClick={({ value }) => {
					// setSelectedInterval(value);
				}}
			/> */}
		</Modal>
	);
}

export function CustomerPlannedServices({ customer, priceList, onChange }) {
	const offerMap = indexBy(prop("sku"), priceList.offers);
	const [selectedItem, setSelectedItem] = useState(null);
	const [offerSelectionVisible, setOfferSelectionVisible] = useState(false);

	const onSubmitUpdate = useCallback(
		(newItem) => {
			const map = indexBy(prop("sku"), customer.plannedServices);
			console.log("newItem", newItem, map);

			if (map[newItem.sku]?.interval === newItem.interval)
				return setSelectedItem(null);

			map[newItem.sku] = newItem;
			const plannedServices = values(map);

			onChange(plannedServices);
			setSelectedItem(null);
		},
		[customer.plannedServices, onChange]
	);

	const onAddService = useCallback(({ sku, interval, isPrimary }) => {
		setOfferSelectionVisible(false);
		setSelectedItem({ sku, interval, isPrimary });
	}, []);

	// const onStopAllServices = useCallback(() => {
	// 	Modal.alert(
	// 		"Behandlungs-Stopp",
	// 		"Soll dieser Kunde ab sofort NICHT mehr behandelt werden?",
	// 		[
	// 			{
	// 				text: "Abbrechen",
	// 			},
	// 			{
	// 				style: "destructive",
	// 				text: "Beh. stoppen",
	// 				onPress: () => {
	// 					onChange(
	// 						customer.plannedServices.map((s) => ({
	// 							...s,
	// 							interval: -1,
	// 						}))
	// 					);
	// 				},
	// 			},
	// 		]
	// 	);
	// }, [customer.plannedServices, onChange]);

	return (
		<>
			<List renderHeader={() => "Geplante Leistungen"}>
				{customer.plannedServices?.map((servicePlan) => (
					<PlannedServiceItem
						key={servicePlan.sku}
						interval={servicePlan.interval}
						offer={offerMap[servicePlan.sku]}
						onClick={() => setSelectedItem(servicePlan)}
					/>
				))}
				{/* {!customer.plannedServices?.length && (
					<List.Item>
						<div
							style={{
								color: "#a4a9b0",
								textAlign: "center",
								fontSize: ".8em",
							}}
						>
							Keine Leistungen geplant
						</div>
					</List.Item>
				)} */}

				{/* <Flex> */}
				<List.Item
					platform="android"
					onClick={() => setOfferSelectionVisible(true)}
					thumb={<FieldTimeOutlined style={{ color: "black" }} />}
					style={{ flex: 1 }}
				>
					Leistung einplanen
				</List.Item>
				{/* <List.Item className="danger" onClick={onStopAllServices}>
						<StopOutlined style={{ marginRight: 8 }} />
						Halt
					</List.Item>
				</Flex> */}
			</List>
			{selectedItem && (
				<PlannedServiceModal
					interval={selectedItem.interval}
					offer={offerMap[selectedItem.sku]}
					onCancel={() => setSelectedItem(null)}
					onSubmit={onSubmitUpdate}
				/>
			)}
			{offerSelectionVisible && (
				<OfferSelectionModal
					customer={customer}
					onCancel={() => setOfferSelectionVisible(false)}
					onSubmit={onAddService}
				/>
			)}
		</>
	);
}
