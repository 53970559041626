import { DisconnectOutlined } from "@ant-design/icons";
import { WingBlank } from "antd-mobile";
import { useContext } from "react";
import { OfflineModeContext } from "../contexts/offline";

export function OfflineModeIndicator() {
	const offlineMode = useContext(OfflineModeContext);
	return offlineMode.isOffline ? (
		<WingBlank>
			<DisconnectOutlined />
		</WingBlank>
	) : null;
}
