/* eslint-disable react-hooks/exhaustive-deps */
import { indexBy } from "ramda";
import { useMemo } from "react";

export const useRamda = <TReturn, TArgs extends any[]>(
	func: (...args: TArgs) => TReturn,
	...args: TArgs
): TReturn => useMemo(() => func(...args), args);

export const useIndex = <T, TKey extends string | number>(
	fn: (o: T) => TKey,
	list: T[]
) => useMemo(() => list && indexBy(fn, list), [list]);
