import { Modal } from "antd-mobile";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

export function ModalPrompt({
	when,
	onOk,
	onCancel,
	title,
	actions,
	children,
}) {
	const history = useHistory();

	const [showPrompt, setShowPrompt] = useState(false);
	const [currentPath, setCurrentPath] = useState("");
	const [currentAction, setCurrentAction] = useState("");
	const unblockCallback = useRef();

	useEffect(() => {
		if (when) {
			unblockCallback.current = history.block((prompt, action) => {
				setCurrentPath(prompt.pathname);
				setCurrentAction(action);
				setShowPrompt(true);
				return false;
			});
		} else {
			if (unblockCallback.current) {
				unblockCallback.current();
			}
			unblockCallback.current = undefined;
		}

		return () => {
			if (unblockCallback.current) {
				unblockCallback.current();
			}
		};
	}, [history, when]);

	const reNavigate = useCallback(() => {
		if (unblockCallback.current) {
			unblockCallback.current();
		}

		if (currentAction === "POP") {
			history.goBack();
		} else {
			history.push(currentPath);
		}
	}, [history, currentPath, currentAction]);

	const handleOK = useCallback(async () => {
		if (onOk) {
			const canRoute = await Promise.resolve(onOk());
			if (canRoute) reNavigate();
		}
	}, [onOk, reNavigate]);

	const handleCancel = useCallback(async () => {
		if (onCancel) {
			const canRoute = await Promise.resolve(onCancel());
			if (canRoute) reNavigate();
		}
		setShowPrompt(false);
	}, [onCancel, reNavigate]);

	return showPrompt ? (
		<Modal
			transparent
			title={title}
			visible={showPrompt}
			onOk={handleOK}
			onCancel={handleCancel}
			maskClosable
			footer={actions.map(({ text, style, onPress }) => ({
				text,
				style,
				onPress: () =>
					onPress({ proceed: handleOK, cancel: handleCancel }),
			}))}
		>
			{children}
		</Modal>
	) : null;
}
