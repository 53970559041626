/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentOptionsModel = {
    type: PaymentOptionsModel.type;
    amountReceived?: number | null;
}

export namespace PaymentOptionsModel {

    export enum type {
        B = 'B',
        Q = 'Q',
        S = 'S',
        P = 'P',
        E = 'E',
        F = 'F',
        L = 'L',
        Z = 'Z',
    }


}
