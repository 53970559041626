import { indexBy, max } from "ramda";
import { useMemo } from "react";
import { CustomerModel, OfferModel } from "../client";

export function useOfferGrid(offers: OfferModel[], customer: CustomerModel) {
	return useMemo(() => {
		if (!offers || !customer) return;

		const plannedBySku = indexBy(
			(s) => s.sku,
			customer.plannedServices || []
		);

		const custOffers = offers.filter((o) => {
			if (o.type === "male" && customer.title === "Frau") return false;
			if (o.type === "female" && customer.title === "Herr") return false;
			return true;
		});

		// Reserve space for item grid
		const maxSort = custOffers.map((o) => o.sort || 0).reduce(max);
		const arr = new Array(maxSort > 0 ? maxSort : 0);

		for (const offer of custOffers) {
			if (offer.sort && offer.sort > 0) {
				arr[offer.sort - 1] = {
					offer,
					planned: plannedBySku[offer.sku],
				};
			}
		}

		const empty = {};
		for (let i = 0; i < arr.length; i++) {
			if (arr[i] === undefined) {
				arr[i] = empty;
			}
		}

		console.log("offer grid:", arr);

		return arr;
	}, [offers, customer]);
}
