/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OfferModel = {
    sku: string;
    description?: string;
    price: number;
    /**
     * Interval in days
     */
    interval?: number;
    isService?: boolean;
    isPrimary?: boolean;
    type?: OfferModel.type;
    /**
     * Sort index
     */
    sort?: number;
}

export namespace OfferModel {

    export enum type {
        COMMON = 'common',
        SALES = 'sales',
        FEMALE = 'female',
        MALE = 'male',
    }


}
