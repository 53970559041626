import { HomeOutlined } from "@ant-design/icons";
import { Grid, InputItem, List, Modal } from "antd-mobile";
import * as ObjectID from "bson-objectid";
import { Formik } from "formik";
import log from "loglevel";
import { useContext } from "react";
import { DatabaseContext } from "../contexts/database";
import { NavigationContext } from "../contexts/navigation";
import { useSubmitCustomerData } from "../data/mutations";

const Item = List.Item;

export function CustomerCreation({ order, workplace, onClose }) {
	const db = useContext(DatabaseContext);
	const nav = useContext(NavigationContext);
	const mutation = useSubmitCustomerData();

	const units =
		workplace.units.map((unit) => ({
			value: unit,
			label: `${unit}`,
		})) || [];

	const initialCustomer = {
		id: ObjectID().toHexString(),
		workplace: workplace.id,
		workplaceNo: workplace.workplaceNo,
		isActive: true,
		title: "",
		firstName: "",
		lastName: "",
		preferredPayment: { type: "B" },
		plannedServices: [],
		attributes: {},
		address: {},
	};

	const createCustomer = async (values) => {
		try {
			await mutation.mutateAsync(values);
		} catch (err) {
			if (err.response) {
				console.log("customer creation error:", err);
				return;
			}
		}
		nav.push(`/order/${order.id}/customer/${values.id}`);
	};

	const handleSubmit = async (values) => {
		if (!values.title || !values.lastName) return;

		// Check for existing customers
		const existing = await db.tryFindExistingCustomer(values);

		if (existing) {
			log.warn("found existing customer:", existing);

			Modal.alert(
				"Kunde bereits vorhanden?",
				<div>
					<p>Ein ähnlicher Kunde ist bereits vorhanden:</p>
					<span style={{ color: "black", fontSize: 16 }}>
						{existing.title} {existing.lastName},{" "}
						{existing.firstName}
					</span>
				</div>,
				[
					{
						text: "Anzeigen",
						onPress: () => {
							nav.push(
								`/order/${order.id}/customer/${existing.id}`
							);
						},
					},
					{
						text: "Neu anlegen",
						onPress: () => createCustomer(values),
					},
				]
			);
			return;
		}

		// No matches found, create new customer
		await createCustomer(values);
	};

	return (
		<Formik initialValues={initialCustomer} onSubmit={handleSubmit}>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,

				setFieldTouched,
				setFieldValue,

				handleSubmit,
				isSubmitting,
				status,
				submitForm,
			}) => {
				return (
					<form onSubmit={handleSubmit}>
						<Modal
							transparent
							title={"Neuer Kunde"}
							visible
							footer={[
								{
									text: "Kunde anlegen",
									onPress: () => submitForm(),
								},
							]}
							onClose={onClose}
						>
							<InputItem
								autoFocus
								value={values.title}
								placeholder="Hier eingeben..."
								onChange={(value) => {
									setFieldValue("title", value);
									setFieldTouched("title", true);
								}}
							>
								Anrede:
							</InputItem>
							<InputItem
								value={values.lastName}
								placeholder="Hier eingeben..."
								onChange={(value) => {
									setFieldValue("lastName", value);
									setFieldTouched("lastName", true);
								}}
							>
								Name:
							</InputItem>
							<InputItem
								value={values.firstName}
								placeholder="Hier eingeben..."
								onChange={(value) => {
									setFieldValue("firstName", value);
									setFieldTouched("firstName", true);
								}}
							>
								Vorname:
							</InputItem>
							<InputItem
								value={values.room}
								placeholder="Nr..."
								onChange={(value) => {
									setFieldValue("room", value);
									setFieldTouched("room", true);
								}}
								extra={`WB ${values.unit || "?"}`}
							>
								Zimmer:
							</InputItem>
							<Item>
								<Grid
									className="customer-unit-grid"
									square={false}
									data={units}
									columnNum={3}
									renderItem={({ value, label }) => (
										<div
											className={`customer-unit ${
												(!values.unit && !value) ||
												values.unit === value
													? "selected"
													: ""
											}`}
										>
											<HomeOutlined />
											<span className="customer-unit-label">
												{value ? label : "?"}
											</span>
										</div>
									)}
									onClick={({ value }) => {
										setFieldValue("unit", value);
										setFieldTouched("unit", true);
									}}
								/>
							</Item>
						</Modal>
					</form>
				);
			}}
		</Formik>
	);
}
