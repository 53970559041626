import Dexie from "dexie";
import Fuse from "fuse.js";
import log from "loglevel";
import { QueryClient } from "react-query";
import {
	AttributeModel,
	CustomerControllerService,
	CustomerModel,
	EmployeeControllerService,
	EmployeeModel,
	PriceListModel,
	ServiceModel,
	WorkLogModel,
	WorkOrderControllerService,
	WorkOrderModel,
	WorkplaceControllerService,
	WorkplaceModel,
} from "../client";
import { Outbox } from "./outbox";
import {
	submitCustomerData,
	submitLogMessage,
	updateWorkOrderService,
} from "./mutations";

export const queryClient = new QueryClient();

export type TableName = keyof Pick<
	FriseurDatabase,
	{
		[K in keyof FriseurDatabase]: FriseurDatabase[K] extends Dexie.Table
			? K
			: never;
	}[keyof FriseurDatabase]
>;

export type TableNameOf<T, TKey> = keyof Pick<
	FriseurDatabase,
	{
		[K in keyof FriseurDatabase]: FriseurDatabase[K] extends Dexie.Table<
			T,
			TKey
		>
			? K
			: never;
	}[keyof FriseurDatabase]
>;

export type TableType = FriseurDatabase[TableName];
export type ModelType = TableType extends Dexie.Table<infer T> ? T : never;

type SyncEvent = {
	table: TableName;
	syncedAt: Date;
	pending: boolean;
};

type ObjectSync = {
	table: TableName;
	id: string;
};

export type OutboxModel = {
	id: number;
	createdAt: Date;
	table: TableName;
	data: any;
	lastFailedAt?: Date;
	error?: string;
};

export class FriseurDatabase extends Dexie {
	public workplaces: Dexie.Table<WorkplaceModel, string>;
	public employees: Dexie.Table<EmployeeModel, string>;
	public customers: Dexie.Table<CustomerModel, string>;
	public orders: Dexie.Table<WorkOrderModel, string>;
	public services: Dexie.Table<ServiceModel, string>;
	public prices: Dexie.Table<PriceListModel, string>;
	public workLog: Dexie.Table<WorkLogModel, string>;
	public attributes: Dexie.Table<AttributeModel, string>;
	public syncLog: Dexie.Table<SyncEvent, string>;
	public objSync: Dexie.Table<ObjectSync, string>;
	public outbox: Outbox;

	private syncTaskRunning?: Promise<void>;
	private syncPromise?: {
		resolve: (value: void) => void;
		reject: (reason: any) => void;
	};

	public constructor() {
		super("FriseurDatabase");

		this.version(22).stores({
			workplaces: "id",
			employees: "id,&employeeNo",
			customers: "id,workplace",
			orders: "id,scheduleNo,workplace",
			services: "[order+customer],order,customer",
			prices: "id",
			workLog: "id,workplace",
			attributes: "id,&attr",
			syncLog: "table",
			objSync: "[table+id]",
			outbox: "++id,table",
		});

		this.workplaces = this.table("workplaces");
		this.employees = this.table("employees");
		this.customers = this.table("customers");
		this.orders = this.table("orders");
		this.services = this.table("services");
		this.prices = this.table("prices");
		this.workLog = this.table("workLog");
		this.attributes = this.table("attributes");
		this.syncLog = this.table("syncLog");
		this.objSync = this.table("objSync");

		this.outbox = new Outbox(this, this.table("outbox"));

		// Handle customer update events
		this.outbox.handler("customers", submitCustomerData);
		this.outbox.handler("services", updateWorkOrderService);
		this.outbox.handler("workLog", submitLogMessage);
	}

	public async reset() {
		await this.delete();
		await this.open();
	}

	public async syncOrderData(order: WorkOrderModel) {
		log.info("sync order:", order);
		const res = await Promise.all([
			// Sync order data
			this.orders.put(order),

			// Sync services for order
			WorkOrderControllerService.getWorkOrderServices(order.id).then(
				(services) => this.services.bulkPut(services)
			),

			// Sync workplace master data
			WorkplaceControllerService.getWorkplace(
				order.workplace as string
			).then((workplace) => this.workplaces.put(workplace)),

			// Sync prices for workplace
			WorkplaceControllerService.getWorkplacePricelist(
				order.workplace as string
			).then((priceList) => this.prices.put(priceList)),

			// Sync messages for workplace
			WorkplaceControllerService.getWorkplaceLog(
				order.workplace as string
			).then((logs) => this.workLog.bulkPut(logs)),

			// Sync customers for workplace
			WorkplaceControllerService.getWorkplaceCustomers(
				order.workplace as string
			).then((customers) => this.customers.bulkPut(customers)),

			// Sync past services for workplace
			WorkplaceControllerService.getWorkplaceCustomerServices(
				order.workplace as string
			).then((services) => {
				log.info(
					"loaded %d services at workplace %s",
					services.length,
					order.workplace
				);
				return this.services.bulkPut(services);
			}),
		]);

		await this.objSync.put({ table: "orders", id: order.id });
		queryClient.invalidateQueries(["orders", order.id]);

		log.info("sync order (%s) result:", order.id, res);
	}

	public async syncCommonData() {
		log.debug("syncCommonData");
		const res = await Promise.all([
			// Sync customers attributes
			CustomerControllerService.getCustomerAttributes().then(
				async (attributes) => {
					await this.attributes.clear();
					await this.attributes.bulkPut(attributes);
				}
			),

			// Sync employee master data
			EmployeeControllerService.getEmployees().then((employees) =>
				this.employees.bulkPut(employees)
			),
		]);

		log.info("sync common result:", res);
	}

	public async getLastOrderSync() {
		return await this.syncLog.get("orders");
	}

	private async getOrdersToSync() {
		const orderSyncStatus = await this.syncLog.get("orders");

		// Full download if never synced
		if (!orderSyncStatus) {
			return await WorkOrderControllerService.getWorkOrders();
		}

		this.syncTaskRunning = new Promise((resolve, reject) => {
			this.syncPromise = { resolve, reject };
		});

		// Request changed documents since last sync
		return await WorkOrderControllerService.getWorkOrderChanges(
			orderSyncStatus.syncedAt.toISOString()
		);
	}

	public async syncOrders() {
		// No sync if already in progress
		if (this.syncTaskRunning) {
			return await this.syncTaskRunning;
		}

		try {
			const orders = await this.getOrdersToSync();
			log.info(
				"---------------------------------------------------\n",
				"Order Synchronization\n",
				"---------------------------------------------------"
			);
			log.info("orders to sync:", orders);

			// Sync orders that have changed
			if (orders && orders.length) {
				await this.syncLog.update("orders", { pending: true });

				await this.syncCommonData();

				for (const order of orders) {
					const objSync = await this.objSync.get([
						"orders",
						order.id,
					]);

					log.debug(
						"order sync status: %s =>",
						order.id,
						{
							updatedAt: order.updatedAt,
							createdAt: order.createdAt,
							billedAt: order.billedAt,
							deletedAt: order.deletedAt,
						},
						objSync
					);

					// Neuer Auftrag kommt rein
					if (!objSync) {
						// Neue, bereits abgearbeitete Aufträge ignorieren
						if (order.billedAt || order.deletedAt) {
							log.info(
								"skipping new order %s =>",
								order.id,
								order.deletedAt ? "deleted" : "billed"
							);
							continue;
						}
						// Neuen Auftrag komplett runterladen
						await this.syncOrderData(order);
					}
					// Bestehender Auftrag wurde abgerechnet
					else if (order.billedAt || order.deletedAt) {
						// Geänderten Auftrag nochmal runterladen
						await this.syncOrderData(order);
					}
				}

				queryClient.invalidateQueries(["orders"]);

				// Determine new sync timestamp
				const syncedAt = new Date(
					orders
						.filter((o) => o.updatedAt)
						.map((o) => Date.parse(o.updatedAt as string))
						.reduce((a, o) => Math.max(a, o), 0)
				);

				log.info("synced at:", syncedAt);
				await this.syncLog.put({
					table: "orders",
					syncedAt,
					pending: false,
				});
			}

			this.syncTaskRunning = undefined;
			this.syncPromise?.resolve();
		} catch (err) {
			log.error("sync error:", err);
			this.syncTaskRunning = undefined;
			this.syncPromise?.reject(err);
		}

		queryClient.invalidateQueries(["syncLog", "orders"]);
	}

	// async clearOrderData(order: WorkOrderModel) {
	// 	log.info("clearOrderData:", order);

	// 	await this.services.where({ order: order.id }).delete();
	// 	await this.orders.delete(order.id);

	// 	queryClient.invalidateQueries("orders", undefined, {});
	// }

	public async tryFindExistingCustomer(customer: CustomerModel) {
		// Get all customers at that workplace
		const customers = await this.customers
			.where({ workplace: customer.workplace })
			.toArray();

		const customersWithName = customers
			.filter((c) => c.isActive)
			.map((c) => ({
				...c,
				fullName: `${c.firstName} ${c.lastName}`.trim(),
			}))
			.filter((c) => c.fullName);

		// Setup the fuzzy search instance
		const fuse = new Fuse(customersWithName, {
			includeScore: true,
			ignoreLocation: true,
			threshold: 0.4,
			keys: ["fullName"],
		});

		// const queries = [];
		// if (customer.title) queries.push({ title: customer.title });
		// if (customer.firstName) queries.push({ firstName: customer.firstName });
		// if (customer.lastName) queries.push({ lastName: customer.lastName });

		// if (queries.length === 0) return;

		// Try to find existing customer by name
		// const matches = fuse.search({
		// 	$or: queries,
		// });

		const matches = fuse.search({
			$and: [
				{ fullName: customer.firstName || "" },
				{ fullName: customer.lastName || "" },
			],
		});
		log.info("fuzzy search results:", matches);

		// Return the first result if found
		const result = matches.find((_) => true);

		return result?.item;
	}
}
