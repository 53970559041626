/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ServiceModel } from '../models/ServiceModel';
import type { ServiceModelCreation } from '../models/ServiceModelCreation';
import type { ServiceModelSubmission } from '../models/ServiceModelSubmission';
import type { WorkLogModel } from '../models/WorkLogModel';
import type { WorkLogModelCreation } from '../models/WorkLogModelCreation';
import type { WorkOrderModel } from '../models/WorkOrderModel';
import type { WorkOrderModelCreation } from '../models/WorkOrderModelCreation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WorkOrderControllerService {

    /**
     * @returns WorkOrderModel Success
     * @throws ApiError
     */
    public static getWorkOrders(): CancelablePromise<Array<WorkOrderModel>> {
        return __request({
            method: 'GET',
            path: `/orders`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static createWorkOrders(
        requestBody?: Array<WorkOrderModelCreation>,
    ): CancelablePromise<void> {
        return __request({
            method: 'PUT',
            path: `/orders`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param since
     * @returns WorkOrderModel Success
     * @throws ApiError
     */
    public static getWorkOrderChanges(
        since: string,
    ): CancelablePromise<Array<WorkOrderModel>> {
        return __request({
            method: 'GET',
            path: `/orders/_changes`,
            query: {
                'since': since,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param scheduleNo
     * @param scheduledAt
     * @returns WorkOrderModel Order found
     * @throws ApiError
     */
    public static getWorkOrderBySchedule(
        scheduleNo: number,
        scheduledAt: string,
    ): CancelablePromise<WorkOrderModel> {
        return __request({
            method: 'GET',
            path: `/orders/bySchedule`,
            query: {
                'scheduleNo': scheduleNo,
                'scheduledAt': scheduledAt,
            },
            errors: {
                404: `Order not found`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns WorkOrderModel Order created
     * @throws ApiError
     */
    public static scheduleWorkOrder(
        requestBody?: WorkOrderModelCreation,
    ): CancelablePromise<WorkOrderModel> {
        return __request({
            method: 'PUT',
            path: `/orders/schedule`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id
     * @returns WorkOrderModel Success
     * @throws ApiError
     */
    public static getWorkOrder(
        id: string,
    ): CancelablePromise<WorkOrderModel> {
        return __request({
            method: 'GET',
            path: `/orders/${id}`,
            errors: {
                401: `Unauthorized`,
                404: `Order not found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns WorkOrderModel Order created
     * @throws ApiError
     */
    public static updateWorkOrder(
        id: string,
        requestBody?: WorkOrderModelCreation,
    ): CancelablePromise<WorkOrderModel> {
        return __request({
            method: 'PUT',
            path: `/orders/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id
     * @returns WorkOrderModel Order deleted
     * @throws ApiError
     */
    public static deleteWorkOrder(
        id: string,
    ): CancelablePromise<WorkOrderModel> {
        return __request({
            method: 'DELETE',
            path: `/orders/${id}`,
            errors: {
                401: `Unauthorized`,
                404: `Order not found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateWorkOrderServices(
        id: string,
        requestBody?: Array<ServiceModelCreation>,
    ): CancelablePromise<void> {
        return __request({
            method: 'PUT',
            path: `/orders/${id}/services`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id
     * @returns ServiceModel Success
     * @throws ApiError
     */
    public static getWorkOrderServices(
        id: string,
    ): CancelablePromise<Array<ServiceModel>> {
        return __request({
            method: 'GET',
            path: `/orders/${id}/services`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param orderId
     * @returns ServiceModel Success
     * @throws ApiError
     */
    public static getServiceChanges(
        orderId: string,
    ): CancelablePromise<Array<ServiceModel>> {
        return __request({
            method: 'GET',
            path: `/orders/${orderId}/services/_changes`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param orderId
     * @param customerId
     * @returns ServiceModel Success
     * @throws ApiError
     */
    public static getCustomerOrderedService(
        orderId: string,
        customerId: string,
    ): CancelablePromise<ServiceModel> {
        return __request({
            method: 'GET',
            path: `/orders/${orderId}/services/${customerId}`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param orderId
     * @param customerId
     * @param requestBody
     * @returns ServiceModel Service updated
     * @throws ApiError
     */
    public static submitWorkOrderService(
        orderId: string,
        customerId: string,
        requestBody?: ServiceModelSubmission,
    ): CancelablePromise<ServiceModel> {
        return __request({
            method: 'POST',
            path: `/orders/${orderId}/services/${customerId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                409: `Already billed`,
            },
        });
    }

    /**
     * @param orderId
     * @param customerId
     * @param requestBody
     * @returns ServiceModel Service updated
     * @throws ApiError
     */
    public static updateWorkOrderService(
        orderId: string,
        customerId: string,
        requestBody?: ServiceModelSubmission,
    ): CancelablePromise<ServiceModel> {
        return __request({
            method: 'PUT',
            path: `/orders/${orderId}/services/${customerId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                409: `Already billed`,
            },
        });
    }

    /**
     * @param orderId
     * @param requestBody
     * @returns WorkLogModel Message created
     * @throws ApiError
     */
    public static submitWorkOrderMessage(
        orderId: string,
        requestBody?: WorkLogModelCreation,
    ): CancelablePromise<WorkLogModel> {
        return __request({
            method: 'POST',
            path: `/orders/${orderId}/messages`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Order not found`,
            },
        });
    }

    /**
     * @param orderId
     * @param messageId
     * @param requestBody
     * @returns WorkLogModel Message updated
     * @throws ApiError
     */
    public static updateWorkOrderMessage(
        orderId: string,
        messageId: string,
        requestBody?: WorkLogModelCreation,
    ): CancelablePromise<WorkLogModel> {
        return __request({
            method: 'PUT',
            path: `/orders/${orderId}/messages/${messageId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Order not found`,
            },
        });
    }

}