import axios from "axios";
import log from "loglevel";
import { AuthControllerService, OpenAPI } from "./client";

// Setup backend options
OpenAPI.BASE = process.env.REACT_APP_API_URL;
OpenAPI.WITH_CREDENTIALS = true;

let activeRefreshPromise;

async function refreshAuthToken() {
	const res = await AuthControllerService.refreshToken();
	log.info("Token refresh result:", res);
	return res.status === "success";
}

function getOrStartTokenRefresh() {
	if (activeRefreshPromise) return activeRefreshPromise;

	// Start new token refresh request
	return (activeRefreshPromise = refreshAuthToken().then(
		(res) => {
			activeRefreshPromise = null;
			return res;
		},
		(err) => {
			activeRefreshPromise = null;
			return false;
		}
	));
}

// Add a response interceptor to handle token refresh
axios.interceptors.response.use(
	(response) => response,
	async function (error) {
		const originalRequest = error.config;
		const url = new URL(originalRequest.url);

		console.log("[intercept] req:", { url, error });

		// Don't intercept login and refresh calls
		if (["/auth/login", "/auth/refresh-token"].includes(url.pathname)) {
			throw error;
		}

		if (
			error.response &&
			error.response.status === 401 &&
			originalRequest &&
			!originalRequest._retry
		) {
			originalRequest._retry = true;

			// Wait for pending refresh to succeed and retry request
			const retrySucceeded = await getOrStartTokenRefresh();

			console.log("retry succeeded:", retrySucceeded);

			if (retrySucceeded) {
				await axios(originalRequest);
			}
		}
		throw error;
	}
);
