import React, { createContext, useState } from "react";

export const WorkerRegistrationContext = createContext({
	updateAvailable: false,
	skipWaiting() {},
	forceSync() {},
});

export const WorkerRegistration = ({ children, workerOptions }) => {
	const [updateAvailable, setUpdateAvailable] = useState(false);

	workerOptions.onUpdate = (registration) => {
		setUpdateAvailable(true);
	};

	const ctx = {
		updateAvailable,
		skipWaiting() {
			if (
				workerOptions.registration &&
				workerOptions.registration.waiting
			) {
				console.log("update worker immediately");
				workerOptions.registration.waiting.postMessage({
					type: "SKIP_WAITING",
				});
			}
		},
		forceSync() {
			if (
				workerOptions.registration &&
				workerOptions.registration.active
			) {
				console.log("sync pending requests immediately");
				workerOptions.registration.active.postMessage({
					type: "FORCE_SYNC",
				});
			}
		},
	};
	return (
		<WorkerRegistrationContext.Provider value={ctx}>
			{children}
		</WorkerRegistrationContext.Provider>
	);
};
