import {
	EnvironmentOutlined,
	HomeOutlined,
	MailOutlined,
	PhoneOutlined,
	UserOutlined,
} from "@ant-design/icons";
import {
	Flex,
	Grid,
	Icon,
	InputItem,
	List,
	Modal,
	TextareaItem,
	Toast,
} from "antd-mobile";
import { Formik } from "formik";
import log from "loglevel";
import { ascend, descend, sortWith } from "ramda";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
	useSubmitCustomerData,
	useUpdateWorkOrderService,
} from "../data/mutations";
import {
	useAttributes,
	useCustomer,
	useCustomerOrderedService,
	useCustomerServices,
	useEmployees,
	usePriceList,
	useWorkplace,
} from "../hooks/data";
import { useIndex } from "../hooks/ramda";
import { CustomerAttributeList } from "./CustomerAttributeList";
import "./CustomerDetails.less";
import { CustomerPlannedServices } from "./CustomerPlannedServices";
import { CustomerServiceInterval } from "./CustomerServiceInterval";
import CustomerServiceItem from "./CustomerServiceItem";
import { MenuSaveButton } from "./MenuSaveButton";
import { ModalPrompt } from "./ModalPrompt";
import { PageLoading } from "./PageLoading";
import { PaymentOptionPicker } from "./PaymentOptionPicker";

export const Item = List.Item;

function CustomerDetails({
	match: {
		params: { orderId, customerId },
	},
}) {
	const { data: customer } = useCustomer(customerId);
	const { data: renderedServices } = useCustomerServices(customerId);
	const { data: attributes } = useAttributes(customerId);
	const { data: workplace } = useWorkplace(customer?.workplace);
	const { data: priceList } = usePriceList(workplace?.priceList);
	const { data: service } = useCustomerOrderedService(orderId, customerId);
	const { data: employees } = useEmployees();

	const customerMutation = useSubmitCustomerData();
	const serviceMutation = useUpdateWorkOrderService();

	// const hasUnpayedInvoice = customer?.invoices?.some((i) => !i.payed);

	const history = useHistory();

	const [customerUnitModalVisible, setCustomerUnitModalVisible] =
		useState(false);
	const [customerAddressModalVisible, setCustomerAddressModalVisible] =
		useState(false);

	const employeesById = useIndex((e) => e.id, employees);

	if (
		!customer ||
		!renderedServices ||
		!attributes ||
		!workplace ||
		!priceList ||
		!employees
	) {
		return <PageLoading />;
	}

	// User can submit services unless current order already billed
	const canSubmitNewServices = !renderedServices.some(
		(s) => s.order === orderId && s.billedAt
	);

	// Only show completed services or current order recommendation
	const serviceLog = sortWith(
		[
			ascend((s) => (s.completedAt ? 2 : 1)),
			descend((s) => Date.parse(s.completedAt)),
		],
		renderedServices.filter(
			(s) => (s.order === orderId || s.completedAt) && !s.deletedAt
		)
	);

	const units =
		workplace.units.map((unit) => ({
			value: unit,
			label: `${unit}`,
		})) || [];

	const handleSubmit = async (values, form) => {
		try {
			await customerMutation.mutateAsync(values, {
				onSuccess: () => {
					form.resetForm({ values });

					Toast.success("Änderungen gespeichert", 1);
				},
				onError: (err) => {
					if (err.status)
						Toast.fail(`Fehler beim Verbuchen!\n${err}`, 5);
				},
			});
		} catch (err) {
			log.error("customer update error", err);
		}
		try {
			if (
				service &&
				!service.isDenied &&
				values.plannedServices?.length &&
				values.plannedServices.every((s) => s.interval === -1)
			) {
				// Deny service when all planned services have been disabled
				await serviceMutation.mutateAsync({
					...service,
					isEnrolled: false,
					isDenied: true,
				});
			} else if (
				service &&
				service.isDenied &&
				values.plannedServices?.some((s) => s.interval > 0)
			) {
				// Re-allow service when services have been planned
				await serviceMutation.mutateAsync({
					...service,
					isDenied: false,
				});
			}
		} catch (err) {
			log.error("service update error", err);
		}
	};

	return (
		<Formik
			initialValues={customer}
			enableReinitialize={true}
			onSubmit={handleSubmit}
		>
			{({
				dirty,
				values,
				errors,
				touched,
				handleChange,
				handleBlur,

				setFieldTouched,
				setFieldValue,

				handleSubmit,
				submitForm,
				isSubmitting,
				status,
			}) => {
				return (
					<form onSubmit={handleSubmit}>
						<ModalPrompt
							when={dirty}
							title="Änderungen speichern?"
							onOk={() => true}
							onCancel={async () => {
								await submitForm();
								return true;
							}}
							actions={[
								{
									text: "Verwerfen",
									style: "destructive",
									onPress: (m) => m.proceed(),
								},
								{
									text: "Speichern",
									onPress: (m) => m.cancel(),
								},
							]}
						>
							Einige Ihrer Änderungen wurden noch nicht
							gespeichert!
							<br />
							Wie möchten Sie fortfahren?
						</ModalPrompt>
						{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
						<MenuSaveButton
							touched={touched}
							onSave={handleSubmit}
							isSubmitting={isSubmitting}
						/>
						<List
							renderHeader={
								<div>
									<span>Kundendaten</span>
									<span style={{ float: "right" }}>
										Kd-Nr. {customer.customerNo}
									</span>
								</div>
							}
						>
							<Item
								thumb={<UserOutlined />}
								onClick={() =>
									setCustomerUnitModalVisible(true)
								}
							>
								{values.title} {values.lastName},{" "}
								{values.firstName}
							</Item>
							<Flex>
								<Item
									thumb={<HomeOutlined />}
									onClick={() =>
										setCustomerUnitModalVisible(true)
									}
									style={{ flex: 1 }}
								>
									<span>WB {values.unit || "?"}, </span>
									<span>Zimmer {values.room || "?"}</span>
								</Item>
								<Item
									onClick={() =>
										setCustomerAddressModalVisible(true)
									}
								>
									<Icon type="ellipsis" />
								</Item>
							</Flex>

							<PaymentOptionPicker
								title="Zahlungsart"
								value={values.preferredPayment}
								editable
								onChange={({ type }) => {
									setFieldValue(
										"preferredPayment.type",
										type
									);
									setFieldTouched(
										"preferredPayment.type",
										true
									);
								}}
							/>

							<CustomerServiceInterval
								serviceInterval={values.serviceInterval}
								isDenied={values.isDenied}
								onChange={({ interval, isDenied }) => {
									setFieldValue("serviceInterval", interval);
									setFieldTouched("serviceInterval", true);
									setFieldValue("isDenied", isDenied);
									setFieldTouched("isDenied", true);
								}}
							/>
						</List>
						<CustomerAttributeList
							attributes={attributes}
							customer={customer}
							values={values}
							onChange={(key, value) => {
								const field = `attributes.${key}`;
								setFieldValue(field, value);
								setFieldTouched(field, true);
							}}
						/>
						<CustomerPlannedServices
							customer={values}
							priceList={priceList}
							onChange={(services) => {
								setFieldValue("plannedServices", services);
								setFieldTouched("plannedServices", true);
							}}
						/>
						<List renderHeader={() => "Hinweise"}>
							<TextareaItem
								autoHeight
								maxLength={100}
								value={values.remarks}
								onChange={(value) => {
									setFieldValue("remarks", value);
									setFieldTouched("remarks", true);
								}}
								placeholder="Nichts vermerkt"
							/>
						</List>
						<List renderHeader={() => "Behandlungen"}>
							{canSubmitNewServices && (
								<Item
									arrow="horizontal"
									platform="android"
									onClick={() =>
										history.push(
											`/order/${orderId}/customer/${customer.id}/service`
										)
									}
									thumb={<Icon type="plus" />}
								>
									Umsatz eingeben
								</Item>
							)}
							{serviceLog.map((service) => (
								<CustomerServiceItem
									key={service.order}
									service={service}
									employee={employeesById[service.employee]}
								/>
							))}
						</List>
						<Modal
							transparent
							visible={customerUnitModalVisible}
							onClose={() => setCustomerUnitModalVisible(false)}
							title={`Kunde — Nr. ${customer.customerNo}`}
							footer={[
								// {
								// 	text: "Zurücksetzen",
								// 	onPress: () => {
								// 		updateServiceItem({
								// 			...editSelectedItem,
								// 			price: editSelectedItem.originalPrice,
								// 		});
								// 		setEditSelectedItem(null);
								// 	},
								// },
								{
									text: "Anpassen",
									onPress: () => {
										setCustomerUnitModalVisible(false);
									},
								},
							]}
						>
							<InputItem
								value={values.title}
								placeholder="Hier eingeben..."
								onChange={(value) => {
									setFieldValue("title", value);
									setFieldTouched("title", true);
								}}
							>
								Anrede:
							</InputItem>
							<InputItem
								value={values.lastName}
								placeholder="Hier eingeben..."
								onChange={(value) => {
									setFieldValue("lastName", value);
									setFieldTouched("lastName", true);
								}}
							>
								Name:
							</InputItem>
							<InputItem
								value={values.firstName}
								placeholder="Hier eingeben..."
								onChange={(value) => {
									setFieldValue("firstName", value);
									setFieldTouched("firstName", true);
								}}
							>
								Vorname:
							</InputItem>
							<InputItem
								value={values.room}
								placeholder="Nr..."
								onChange={(value) => {
									setFieldValue("room", value);
									setFieldTouched("room", true);
								}}
								extra={`WB ${values.unit || "?"}`}
							>
								Zimmer:
							</InputItem>
							{/* <InputItem
								value={values.unit}
								placeholder="WB wählen..."
								onChange={(value) => {
									setFieldValue("unit", value);
									setFieldTouched("unit", true);
								}}
							>
								Bereich
							</InputItem> */}
							<Item>
								<Grid
									className="customer-unit-grid"
									square={false}
									data={units}
									columnNum={3}
									renderItem={({ value, label }) => (
										<div
											className={`customer-unit ${
												(!values.unit && !value) ||
												values.unit === value
													? "selected"
													: ""
											}`}
										>
											<HomeOutlined />
											<span className="customer-unit-label">
												{value ? label : "?"}
											</span>
										</div>
									)}
									onClick={({ value }) => {
										setFieldValue("unit", value);
										setFieldTouched("unit", true);
									}}
								/>
							</Item>
						</Modal>
						<Modal
							className="address-modal"
							transparent
							visible={customerAddressModalVisible}
							onClose={() =>
								setCustomerAddressModalVisible(false)
							}
							title={`Adresse — Angehöriger`}
							footer={[
								{
									text: "Anpassen",
									onPress: () => {
										setCustomerAddressModalVisible(false);
									},
								},
							]}
						>
							<InputItem
								className="icon-input-label"
								value={values.address?.name}
								placeholder="Vorname, Nachname..."
								onChange={(value) => {
									setFieldValue("address.name", value);
									setFieldTouched("address.name", true);
								}}
							>
								<UserOutlined />
							</InputItem>
							<Flex direction="row">
								<div style={{ flex: "3" }}>
									<InputItem
										className="icon-input-label"
										value={values.address?.street}
										placeholder="Straße..."
										onChange={(value) => {
											setFieldValue(
												"address.street",
												value
											);
											setFieldTouched(
												"address.street",
												true
											);
										}}
									>
										<HomeOutlined />
									</InputItem>
								</div>
								<div style={{ flex: "1" }}>
									<InputItem
										value={values.address?.houseNo}
										placeholder="Nr."
										onChange={(value) => {
											setFieldValue(
												"address.houseNo",
												value
											);
											setFieldTouched(
												"address.houseNo",
												true
											);
										}}
									/>
								</div>
							</Flex>
							<Flex direction="row">
								<div style={{ flex: "2" }}>
									<InputItem
										className="icon-input-label"
										value={values.address?.postalCode}
										placeholder="PLZ..."
										onChange={(value) => {
											setFieldValue(
												"address.postalCode",
												value
											);
											setFieldTouched(
												"address.postalCode",
												true
											);
										}}
									>
										<EnvironmentOutlined />
									</InputItem>
								</div>
								<div style={{ flex: "3" }}>
									<InputItem
										value={values.address?.city}
										placeholder="Ort..."
										onChange={(value) => {
											setFieldValue(
												"address.city",
												value
											);
											setFieldTouched(
												"address.city",
												true
											);
										}}
									/>
								</div>
							</Flex>
							<InputItem
								className="icon-input-label"
								value={values.address?.phone}
								placeholder="Telefon..."
								onChange={(value) => {
									setFieldValue("address.phone", value);
									setFieldTouched("address.phone", true);
								}}
							>
								<PhoneOutlined />
							</InputItem>
							<InputItem
								className="icon-input-label"
								value={values.address?.email}
								placeholder="E-Mail..."
								onChange={(value) => {
									setFieldValue("address.email", value);
									setFieldTouched("address.email", true);
								}}
							>
								<MailOutlined />
							</InputItem>
						</Modal>
					</form>
				);
			}}
		</Formik>
	);
}

export default CustomerDetails;
