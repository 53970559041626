import { ActivityIndicator, Flex } from "antd-mobile";
import "./PageLoading.less";

export const PageLoading = () => (
	<Flex
		className="page-loading"
		direction="column"
		justify="center"
		align="center"
	>
		<ActivityIndicator size="large" animating />
		<span style={{ marginTop: 16 }}>Inhalt wird geladen...</span>
	</Flex>
);
