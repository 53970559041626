import {
	CheckCircleOutlined,
	HomeOutlined,
	UserAddOutlined,
} from "@ant-design/icons";
import { Badge, List, Tabs } from "antd-mobile";
import { ascend, descend, filter, groupBy, prop, sortWith } from "ramda";
import { useState } from "react";
import Sticky from "react-stickynode";
import {
	useServices,
	useWorkplace,
	useWorkplaceCustomers,
} from "../hooks/data";
import { useNavState } from "../hooks/nav";
import { useIndex } from "../hooks/ramda";
import { useSearch } from "../hooks/search";
import { CustomerCreation } from "./CustomerCreation";
import CustomerItem from "./CustomerItem";
import { PageLoading } from "./PageLoading";
import "./WorkOrderList.less";

function WorkplaceUnitTabBar(props) {
	return (
		<Sticky enabled={true} top=".navigation.am-navbar" innerZ={20}>
			<Tabs.DefaultTabBar {...props} page={5} />
		</Sticky>
	);
}

export const WorkOrderList = ({ order }) => {
	const { data: services } = useServices(order.id);
	const { data: customers } = useWorkplaceCustomers(order.workplace);
	const { data: workplace } = useWorkplace(order.workplace);

	const customerService = useIndex(prop("customer"), services);
	const searchQuery = useSearch();
	const [navState, setNavState] = useNavState();

	if (!services || !customers || !workplace) {
		return <PageLoading />;
	}

	const customersByUnit = groupBy((c) => c.unit || "", customers);
	const unitCustomers = Object.entries(customersByUnit);

	const lowerQuery = searchQuery.trim().toLowerCase();

	const tabs = unitCustomers.map(([unit, customers]) => {
		const isOrdered = order.units.includes(unit);

		const filteredCustomers = searchQuery
			? filter((c) => {
					if (
						(c.lastName &&
							c.lastName.toLowerCase().includes(lowerQuery)) ||
						(c.firstName &&
							c.firstName.toLowerCase().includes(lowerQuery))
					)
						return true;

					const service = customerService[c.id];

					if (
						service &&
						service.items.some(
							(item) =>
								item.sku &&
								item.sku.toLowerCase().includes(lowerQuery)
						)
					)
						return true;
					return false;
			  }, customers)
			: customers;

		const todo = customers.filter((c) => {
			const service = customerService[c.id];
			return service && service.isEnrolled && !service.completedAt;
		});

		const hasAnyCompleted = customers.some(
			(c) => customerService[c.id]?.completedAt
		);
		const hasCustomersTodo = todo.length > 0;

		return {
			title: (
				<Badge
					className={[
						"unit-badge",
						isOrdered && "unit-ordered",
						hasCustomersTodo || searchQuery
							? "unit-todo"
							: hasAnyCompleted
							? "unit-completed"
							: null,
					]}
					text={
						searchQuery ? (
							filteredCustomers.length
						) : hasCustomersTodo ? (
							todo.length
						) : isOrdered && hasAnyCompleted ? (
							<CheckCircleOutlined />
						) : null
					}
					size="small"
				>
					<HomeOutlined style={{ marginRight: 5 }} /> {unit || "?"}
				</Badge>
			),
			unit,
			customers: filteredCustomers,
		};
	});

	// Select or restore the relevant tab on load
	const initialPage =
		navState?.currentTab ||
		unitCustomers.findIndex(([unit, _]) => order.units.includes(unit));

	// Scroll to top of the list on tab switch
	const onTabClick = () => {
		if (window.scrollY <= 40) return;
		window.scrollTo({
			top: 40,
			left: 0,
			behavior: "smooth",
		});
	};

	// Save current selected tab in nav state
	const onTabChange = (tab, currentTab) => {
		setNavState({ currentTab });
	};

	return (
		<>
			<Tabs
				tabs={tabs}
				initialPage={initialPage}
				renderTabBar={WorkplaceUnitTabBar}
				onTabClick={onTabClick}
				onChange={onTabChange}
				swipeable={false}
			>
				{({ unit, customers }) => (
					<WorkplaceUnit
						key={unit}
						unit={unit}
						customers={customers}
						customerService={customerService}
						workplace={workplace}
						order={order}
						searchQuery={searchQuery}
					/>
				)}
			</Tabs>
		</>
	);
};

export const WorkplaceUnit = ({
	unit,
	order,
	workplace,
	customers,
	customerService,
	searchQuery,
}) => {
	const sortCustomersByName = sortWith([
		descend((c) => {
			const service = customerService[c.id];
			if (!service) return 0;

			return service.isEnrolled || service.completedAt
				? 3
				: service.isDenied
				? 1
				: service.isDue
				? 2
				: 0;
		}),
		ascend(prop("title")),
		ascend(prop("lastName")),
		ascend(prop("firstName")),
	]);

	const sortedCustomers = sortCustomersByName(
		filter((c) => c.isActive, customers)
	);

	const [customerCreationVisible, setCustomerCreationVisible] =
		useState(false);

	return (
		<List
			className="order-customer-list"
			renderHeader={
				searchQuery && (
					<div>
						{sortedCustomers.length} Ergebnisse für "{searchQuery}"
						{unit && ` in WB ${unit}`}
					</div>
				)
			}
		>
			{sortedCustomers.map((customer) => (
				<CustomerItem
					key={customer.id}
					customer={customer}
					order={order}
					service={customerService[customer.id]}
				/>
			))}
			<List.Item
				platform="android"
				arrow="horizontal"
				thumb={<UserAddOutlined />}
				onClick={() => setCustomerCreationVisible(true)}
			>
				Kunde anlegen...
			</List.Item>
			{customerCreationVisible && (
				<CustomerCreation
					order={order}
					workplace={workplace}
					onClose={() => setCustomerCreationVisible(false)}
				/>
			)}
		</List>
	);
};
