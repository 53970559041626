import {
	SettingFilled,
	SettingOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Icon, Popover, Switch } from "antd-mobile";
import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/auth";
import { OfflineModeContext } from "../contexts/offline";
import { useSyncStatus } from "../hooks/sync";
import { humanizeTimespan, humanizeTimespanShort } from "../util/datetime";
import "./Menu.less";

export function Menu({ navContext }) {
	const auth = useContext(AuthContext);
	const offlineMode = useContext(OfflineModeContext);
	const [menuVisible, setMenuVisible] = useState(false);
	const syncStatus = useSyncStatus();

	// const onSyncNow = () => {
	// 	serviceWorker.forceSync();
	// 	db?.syncOrders()
	// 		.then(() => Toast.success("Synchronisierung erfolgreich", 2))
	// 		.catch((err) => Toast.fail("Synchronisierung fehlgeschlagen", 3));
	// };

	const menu = [
		<Popover.Item
			key="1"
			value="sync-state"
			className="popover-item-switch"
			// className="item-content-right"
			// icon={
			// 	offlineMode.isOffline || syncStatus.status === "synced" ? (
			// 		<Icon type="check-circle" size="xs" color="#1b7a47" />
			// 	) : (
			// 		<Icon type="cross-circle" size="xs" color="#bbb" />
			// 	)
			// }
			onSelect={() => {
				offlineMode.setOffline(!offlineMode.isOffline);
			}}
			icon={
				<Switch
					className="switch-small non-clickable"
					checked={!offlineMode.isOffline}
				/>
			}
		>
			{offlineMode.isOffline
				? `Offline – ${humanizeTimespanShort(
						offlineMode.offlineSince - Date.now()
				  )
						.replace("vor", "")
						.trim()}`
				: syncStatus.status === "synced"
				? "Online"
				: syncStatus.offlineSince
				? `Offline – ${humanizeTimespan(
						syncStatus.offlineSince - Date.now()
				  )}`
				: "Offline"}
		</Popover.Item>,
		<Popover.Item
			key="2"
			value="user-info"
			icon={<UserOutlined />}
			style={{ whiteSpace: "nowrap" }}
		>
			{auth.isLoggedIn
				? `${auth.userInfo.lastName}, ${auth.userInfo.firstName}`
				: "Nicht angemeldet"}
		</Popover.Item>,
		<Popover.Item
			key="3"
			value="settings"
			icon={<SettingOutlined />}
			onSelect={() => navContext.push("/settings")}
		>
			Einstellungen
		</Popover.Item>,
	];

	if (!auth.isLoggedIn)
		return <SettingFilled onClick={() => navContext.push("/settings")} />;

	return (
		<Popover
			visible={menuVisible}
			mask
			overlay={menu}
			onSelect={(item) => {
				if (typeof item.props.onSelect === "function")
					item.props.onSelect(item);
				setMenuVisible(false);
			}}
		>
			<Icon type="ellipsis" />
		</Popover>
	);
}
