import { UploadOutlined } from "@ant-design/icons";
import { Badge, WingBlank } from "antd-mobile";
import React, { useContext } from "react";
import { DatabaseContext } from "../contexts/database";
// import { WorkerRegistrationContext } from "../contexts/worker";
// import { useSyncStatus } from "../hooks/sync";
import { useOutboxStatus } from "../hooks/data";

export function SyncStatusIndicator() {
	const db = useContext(DatabaseContext);
	// const serviceWorker = useContext(WorkerRegistrationContext);
	// const syncStatus = useSyncStatus();
	const syncStatus = useOutboxStatus();

	const onSyncNow = () => {
		// serviceWorker.forceSync();
		db?.outbox.sync();
		db?.syncOrders();
	};

	if (!syncStatus.unsyncedItems) return null;

	return (
		<WingBlank>
			<Badge text={syncStatus.unsyncedItems} hot>
				<UploadOutlined
					style={{ fontSize: "1.3em" }}
					onClick={onSyncNow}
				/>
			</Badge>
		</WingBlank>
	);
}
