import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../contexts/auth";

function PrivateRoute(props) {
	const auth = useContext(AuthContext);
	return auth.isLoggedIn ? <Route {...props} /> : <Redirect to="/login" />;
}

export default PrivateRoute;
