import { DownloadOutlined, UserOutlined } from "@ant-design/icons";
import {
	ActivityIndicator,
	Checkbox,
	InputItem,
	List,
	Modal,
	Toast,
	WhiteSpace,
} from "antd-mobile";
import { Formik } from "formik";
import { StatusCodes } from "http-status-codes";
import { indexBy } from "ramda";
import React, { useContext, useState } from "react";
import { NavigationContext } from "../contexts/navigation";
import { useUpdateWorkOrderService } from "../data/mutations";
import {
	useCustomer,
	useCustomerOrderedService,
	useOrder,
	usePriceList,
	useWorkplace,
} from "../hooks/data";
import { currencyFormat, totalPrice } from "../util/currency";
import { getOrderTimespan, shortDateFormat } from "../util/datetime";
import { ModalPrompt } from "./ModalPrompt";
import { PageLoading } from "./PageLoading";
import { PaymentOptionPicker } from "./PaymentOptionPicker";
import "./ServiceEntry.less";
import { ServiceItemList } from "./ServiceItemList";

export const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;

function CashPaymentButton({
	priceTotal,
	paymentReceived,
	payment,
	onConfirm,
	onCancel,
}) {
	const [isVisible, setIsVisible] = useState(false);
	const [payedAmount, setPayedAmount] = useState(null);

	const confirmCashPayment = (amount) => {
		onConfirm(amount || priceTotal);
		setIsVisible(false);
	};

	return (
		<>
			<CheckboxItem
				className="service-cash-payment"
				checked={paymentReceived}
				onChange={({ target: { checked } }) => {
					if (checked) {
						setPayedAmount(null);
						setIsVisible(true);
					} else {
						onCancel();
					}
				}}
				extra={
					payment.amountReceived &&
					currencyFormat.format(payment.amountReceived)
				}
			>
				Kunde hat bezahlt
			</CheckboxItem>
			{isVisible && (
				<Modal
					visible
					transparent
					onClose={() => setIsVisible(false)}
					title="Barzahlung"
					footer={[
						{
							text: "OK",
							onPress: () => confirmCashPayment(payedAmount),
						},
					]}
				>
					<InputItem
						type="money"
						editable={false}
						value={priceTotal}
						extra="€"
					>
						Gesamt:
					</InputItem>
					<InputItem
						type="money"
						placeholder={priceTotal}
						onChange={setPayedAmount}
						value={payedAmount}
						onVirtualKeyboardConfirm={() =>
							confirmCashPayment(payedAmount)
						}
						ref={(elm) => elm && elm.focus()}
						extra="€"
					>
						Gegeben:
					</InputItem>
				</Modal>
			)}
		</>
	);
}

function ServiceEntry({
	match: {
		params: { orderId, customerId },
	},
}) {
	const { data: order } = useOrder(orderId);
	const { data: customer } = useCustomer(customerId);
	const { data: workplace } = useWorkplace(order?.workplace);
	const { data: priceList } = usePriceList(workplace?.priceList);
	const { isLoading: serviceLoading, data: orderedService } =
		useCustomerOrderedService(orderId, customerId);

	const nav = useContext(NavigationContext);

	const submission = useUpdateWorkOrderService();

	const handleSubmit = (values, form) => {
		console.log("submit work", values);
		submission
			.mutateAsync(values, {
				onSuccess: () => {
					form.resetForm({ values });
					nav.goToOrder(order.id);

					Toast.success("Erfolgreich verbucht", 1);
				},
				onError: (err) => {
					if (err.status === StatusCodes.CONFLICT) {
						Toast.fail(`Der Umsatz wurde bereits abgerechnet!`, 5);
					} else {
						Toast.fail(`Fehler beim Verbuchen!\n${err}`, 5);
					}
				},
			})
			.catch((err) => {
				console.error("submit failed", { err });
			});
	};

	if (!order || !customer || !workplace || !priceList || serviceLoading) {
		return <PageLoading />;
	}

	const orderDate = Date.parse(order.scheduledAt);
	const [orderStart, orderEnd] = getOrderTimespan(orderDate);
	const currentDate = new Date();
	const serviceDate =
		currentDate >= orderEnd || currentDate < orderStart
			? orderStart
			: currentDate;

	// console.log({
	// 	scheduledAt: order.scheduledAt,
	// 	serviceDate,
	// 	orderStart,
	// 	orderEnd,
	// });

	const offersBySku = indexBy((o) => o.sku, priceList.offers);

	const orderedItems = orderedService?.items.filter(
		({ sku }) => offersBySku[sku].isService
	);

	return (
		<Formik
			initialValues={{
				order: order.id,
				customer: customer.id,
				employee: order.employee,
				payment: customer.preferredPayment,
				paymentReceived: false,
				completedAt: serviceDate.toISOString(),
				...orderedService,
				items: orderedItems || [],
			}}
			onSubmit={handleSubmit}
		>
			{({
				dirty,
				values,
				errors,
				touched,

				setFieldTouched,
				setFieldValue,

				handleSubmit,
				submitForm,
				isSubmitting,
				status,
			}) => {
				const priceTotal = totalPrice(values.items);
				const inputIsValid = values.items.some(() => true);

				return (
					<form onSubmit={handleSubmit}>
						<ModalPrompt
							when={dirty}
							title="Eingaben verwerfen?"
							cancelText="Cancel"
							okText="Confirm"
							onOk={() => true}
							onCancel={() => false}
							actions={[
								{
									text: "Abbrechen",
									style: "cancel",
									onPress: (m) => m.cancel(),
								},
								{
									text: "Verwerfen",
									style: "destructive",
									onPress: (m) => m.proceed(),
								},
							]}
						>
							Ihre Buchung wurde noch nicht abgeschlossen! Möchten
							Sie die Eingaben wirklich verwerfen?
						</ModalPrompt>
						<List
							renderHeader={
								<div>
									<span>Umsatzeingabe</span>
									<span style={{ float: "right" }}>
										{workplace.shortName}
										{" – "}
										{shortDateFormat.format(orderDate)}
									</span>
								</div>
							}
						>
							<Item thumb={<UserOutlined />}>
								{customer.title} {customer.lastName},{" "}
								{customer.firstName}
							</Item>
							<PaymentOptionPicker
								title="Zahlungsart"
								value={values.payment}
								onChange={(value) => {
									setFieldValue("payment", value);
									setFieldTouched("payment", true);
									if (
										value.type !== "B" &&
										values.paymentReceived
									) {
										setFieldValue("paymentReceived", false);
										setFieldTouched(
											"paymentReceived",
											true
										);
									}
								}}
							/>
						</List>

						<ServiceItemList
							items={values.items}
							offers={priceList.offers}
							customer={customer}
							onChange={(items) => {
								console.log("new items:", items);
								setFieldValue("items", items);
								setFieldTouched("items", true);
							}}
						/>

						<WhiteSpace size="sm" />

						<List>
							<Item
								className="service-total-price"
								extra={currencyFormat.format(priceTotal)}
							>
								Gesamt:
							</Item>

							<CashPaymentButton
								priceTotal={priceTotal}
								paymentReceived={values.paymentReceived}
								payment={values.payment}
								onConfirm={(amount) => {
									setFieldValue("paymentReceived", true);
									setFieldTouched("paymentReceived", true);
									setFieldValue("payment.type", "B"); // Barzahlung
									setFieldTouched("payment.type", true);
									setFieldValue(
										"payment.amountReceived",
										amount
									);
									setFieldTouched(
										"payment.amountReceived",
										true
									);
								}}
								onCancel={() => {
									setFieldValue("paymentReceived", false);
									setFieldTouched("paymentReceived", true);
									setFieldValue(
										"payment.amountReceived",
										undefined
									);
									setFieldTouched(
										"payment.amountReceived",
										true
									);
								}}
							/>
						</List>

						<WhiteSpace size="lg" />

						<List>
							<Item
								disabled={!inputIsValid || submission.isLoading}
								arrow="horizontal"
								platform="android"
								thumb={
									submission.isLoading ? (
										<ActivityIndicator />
									) : (
										<DownloadOutlined />
									)
								}
								onClick={
									inputIsValid &&
									!submission.isLoading &&
									submitForm
								}
								multipleLine
								className="primary"
							>
								Buchung abschließen
							</Item>
						</List>
					</form>
				);
			}}
		</Formik>
	);
}

export default ServiceEntry;
