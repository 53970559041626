import { List, SwipeAction } from "antd-mobile";
import React from "react";
import { currencyFormat } from "../util/currency";
import "./ServiceItem.less";

const Item = List.Item;

export function PriceDisplay({ price, originalPrice }) {
	return (
		<>
			{originalPrice && (
				<span className="price-original">
					{currencyFormat.format(originalPrice)}
				</span>
			)}
			<span className="price-basic">{currencyFormat.format(price)}</span>
		</>
	);
}

export function ServiceItem({ service, thumb, onClick, onDelete }) {
	return (
		<SwipeAction
			autoClose
			left={[
				{
					text: " ",
					className: "service-item-remove",
					onPress: onDelete,
					style: {
						backgroundColor: "#f4333c",
						color: "white",
					},
				},
			]}
			onOpen={() => {
				onDelete();
				console.log("open");
			}}
		>
			<Item
				className="service-item"
				thumb={thumb}
				// thumb={<span>{service.id}.</span>}
				// thumb={
				// 	service.payed ? (
				// 		<CheckOutlined
				// 			style={{ fontSize: "1.6em", color: "#1b7a47" }}
				// 		/>
				// 	) : (
				// 		<ExclamationOutlined
				// 			style={{ fontSize: "1.6em", color: "#f4333c" }}
				// 		/>
				// 	)
				// }
				extra={
					<PriceDisplay
						price={service.price}
						originalPrice={service.originalPrice}
					/>
				}
				error={service.price < 0}
				onClick={onClick}
				platform="android"
			>
				{service.description}
				{/* {service.services.map((service) => (
				<Badge key={service} text={service} className="service-tag" />
			))} */}
			</Item>
		</SwipeAction>
	);
}
