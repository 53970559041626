import { Flex } from "antd-mobile";
import "antd-mobile/dist/antd-mobile.less";
import "antd-mobile/lib/style/themes/default.less";
import React from "react";
import { QueryClientProvider } from "react-query";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	useLocation,
} from "react-router-dom";
import "./App.css";
import CustomerDetails from "./components/CustomerDetails";
import Login from "./components/Login";
import Navigation from "./components/Navigation";
import { OrderDetails } from "./components/OrderDetails";
import { OrderOverview } from "./components/OrderOverview";
import PrivateRoute from "./components/PrivateRoute";
import ServiceEntry from "./components/ServiceEntry";
import Settings from "./components/Settings";
import { AuthenticatedContext } from "./contexts/auth";
import { queryClient } from "./data/database";
import "./theme.less";

function Content() {
	const location = useLocation();
	return (
		<Switch location={location}>
			<Route path="/login" exact component={Login} />
			<Route path="/settings" exact component={Settings} />
			<PrivateRoute
				path="/order/:orderId/customer/:customerId"
				exact
				component={CustomerDetails}
			/>
			<PrivateRoute
				path="/order/:orderId/customer/:customerId/service"
				exact
				component={ServiceEntry}
			/>
			<PrivateRoute path="/order/:orderId" component={OrderDetails} />
			<PrivateRoute
				path="/customer/:customerId"
				exact
				component={CustomerDetails}
			/>
			<PrivateRoute path="/" exact component={OrderOverview} />
		</Switch>
	);
}

function App() {
	return (
		<Flex direction="column" align="stretch">
			<AuthenticatedContext>
				<QueryClientProvider client={queryClient}>
					<Router>
						<Navigation>
							<Content />
						</Navigation>
					</Router>
				</QueryClientProvider>
			</AuthenticatedContext>
		</Flex>
	);
}

export default App;
