import { EuroOutlined } from "@ant-design/icons";
import { List, Picker } from "antd-mobile";

const paymentOptions = [
	[
		{ label: "B - Barzahlung", value: "B" },
		{ label: "E - Privat Scheck", value: "E" },
		{ label: "F - Fax", value: "F" },
		{ label: "P - Privat Bar", value: "P" },
		{ label: "Q - Quittiert", value: "Q" },
		{ label: "S - Scheck", value: "S" },
		{ label: "Z - Einzug", value: "Z" },
	],
];

export const PaymentOptionItem = ({ extra, ...props }) => {
	return (
		<List.Item thumb={<EuroOutlined />} {...props}>
			{extra}
		</List.Item>
	);
};

export const PaymentOptionPicker = ({
	value,
	onChange,
	editable,
	...props
}) => {
	if (editable === false) {
		const label = paymentOptions[0].find(
			(o) => o.value === value.type
		)?.label;
		return <PaymentOptionItem extra={label} />;
	}

	return (
		<Picker
			data={paymentOptions}
			{...props}
			okText="Auswählen"
			dismissText="Schließen"
			cols={1}
			cascade={false}
			value={[value.type]}
			onPickerChange={(v) => console.log("pick value:", v)}
			onChange={([type]) => onChange({ type })}
			onOk={(v) => console.log("confirm value:", v)}
		>
			<PaymentOptionItem />
		</Picker>
	);
};
