import {
	ClockCircleOutlined,
	HomeOutlined,
	UserOutlined,
	WarningOutlined,
} from "@ant-design/icons";
import { Badge, Icon, List } from "antd-mobile";
import { Brief } from "antd-mobile/lib/list/ListItem";
import { indexBy, sortBy } from "ramda";
import { useContext } from "react";
import { NavigationContext } from "../contexts/navigation";
import {
	useServices,
	useWorkplace,
	useWorkplaceCustomers,
} from "../hooks/data";
import {
	currencyFormat,
	currencyNumberFormat,
	totalPrice,
} from "../util/currency";
import { timeFormat } from "../util/datetime";
import "./CompletedWorkList.less";
import { PageLoading } from "./PageLoading";

export function CompletedWorkList({ order }) {
	const nav = useContext(NavigationContext);
	const { data: services } = useServices(order.id);
	const { data: customers } = useWorkplaceCustomers(order.workplace);
	const { data: workplace } = useWorkplace(order.workplace);

	if (!services || !customers || !workplace) {
		return <PageLoading />;
	}

	const customersById = indexBy((cust) => cust.id, customers);
	const servicedCustomers = services
		.filter((svc) => svc.completedAt)
		.map((service) => ({
			service,
			customer: customersById[service.customer],
			total: totalPrice(service.items),
		}));

	const enrolledCustomers = services.filter((svc) => svc.isEnrolled);

	const sortedCustomers = sortBy(
		({ service }) => service.completedAt,
		servicedCustomers
	);

	const orderTotal = servicedCustomers
		.filter(({ service }) => service.completedAt)
		.reduce((t, { total }) => t + total, 0);

	const cashTotal = servicedCustomers
		.filter(
			({ service }) =>
				service.paymentReceived && service.payment.type === "B"
		)
		.reduce(
			(
				t,
				{
					total,
					service: {
						payment: { amountReceived },
					},
				}
			) => t + (amountReceived || 0),
			0
		);

	return (
		<List className="order-work-list">
			<List.Item
				className="order-total-done"
				extra={
					<span>
						{servicedCustomers.length}
						{" / "}
						{enrolledCustomers.length}
						<UserOutlined style={{ marginLeft: 6 }} />
					</span>
				}
			>
				Erledigt / Angemeldet
			</List.Item>
			{sortedCustomers.map(({ service, customer, total }) => (
				<List.Item
					key={service.id}
					className="customer-item"
					platform="android"
					extra={
						<div>
							<div
								style={{
									color: service.paymentReceived
										? service.payment.amountReceived >=
										  total
											? "#1b7a47"
											: "#ff8f00"
										: "#f4333c",
								}}
							>
								{service.paymentReceived ? (
									service.payment.amountReceived >= total ? (
										<Icon
											type="check"
											style={{ marginRight: 4 }}
										/>
									) : (
										<WarningOutlined
											style={{ marginRight: 6 }}
										/>
									)
								) : (
									<Badge
										text={service.payment.type}
										style={{
											background: "#f4333c",
											marginRight: 6,
											marginBottom: 1,
											fontSize: ".7em",
											height: "1.4em",
											padding: "0 4px",
										}}
									/>
								)}
								{service.payment?.amountReceived < total &&
									currencyNumberFormat.format(
										service.payment.amountReceived
									) + " / "}
								{currencyFormat.format(total)}
							</div>
							<small>
								<span>
									<HomeOutlined /> {customer.unit}
								</span>
								<span style={{ marginLeft: 8 }}>
									<ClockCircleOutlined />{" "}
									{timeFormat.format(
										new Date(service.completedAt)
									)}
								</span>
							</small>
						</div>
					}
					onClick={() => {
						nav.push(`/order/${order.id}/customer/${customer.id}`);
					}}
				>
					{customer.title} {customer.lastName}, {customer.firstName}
					<Brief>
						{service.items?.map((service) => (
							<Badge
								key={service.sku}
								text={service.sku}
								className="service-tag"
							/>
						))}
					</Brief>
				</List.Item>
			))}
			{/* {Array.from(new Array(50), (_, i) => (
				<List.Item>Item {i}</List.Item>
			))} */}
			<List.Item
				className="order-total-price"
				extra={
					<span>
						{currencyFormat.format(cashTotal)}
						{" / "}
						{currencyFormat.format(orderTotal)}
					</span>
				}
			>
				Bar / Gesamt:
			</List.Item>
		</List>
	);
}
