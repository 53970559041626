import React, { createContext } from "react";
import { FriseurDatabase } from "../data/database";

export const DatabaseContext = createContext<FriseurDatabase | undefined>(
	undefined
);

export const IndexedDbContext: React.FC = ({ children }) => {
	const db = new FriseurDatabase();

	return (
		<DatabaseContext.Provider value={db}>
			{children}
		</DatabaseContext.Provider>
	);
};
