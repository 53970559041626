import {
	DatabaseOutlined,
	FieldTimeOutlined,
	LogoutOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Icon, List, Modal, Switch, Toast } from "antd-mobile";
import { useContext } from "react";
import { AuthContext } from "../contexts/auth";
import { DatabaseContext } from "../contexts/database";
import { WorkerRegistrationContext } from "../contexts/worker";
import { useSyncLogStatus } from "../hooks/data";
import { useSyncStatus } from "../hooks/sync";
import { humanizeTimespan, shortDateTimeFormat } from "../util/datetime";
import "./Settings.less";

const Item = List.Item;
function Settings() {
	const db = useContext(DatabaseContext);
	const auth = useContext(AuthContext);
	const serviceWorker = useContext(WorkerRegistrationContext);
	const syncStatus = useSyncStatus();

	const onSyncNow = () => {
		serviceWorker.forceSync();
		db?.syncOrders()
			.then(() => Toast.success("Synchronisierung erfolgreich", 2))
			.catch((err) => Toast.fail("Synchronisierung fehlgeschlagen", 3));
	};

	const onResetDatabase = () => {
		db?.reset()
			.then(() => Toast.success("Datenbank zurückgesetzt"))
			.catch((err) =>
				Toast.fail(`Zurücksetzen fehlgeschlagen: ${err}`, 3)
			);
	};

	const { data: lastSync } = useSyncLogStatus("orders");

	const onTryLogout = () => {
		auth.logout();
		// db.reset();
	};

	return (
		<>
			<List renderHeader={() => "Allgemein"}>
				{syncStatus.status === "synced" ? (
					<Item
						thumb={<Icon type="check-circle" color="#1b7a47" />}
						extra={
							lastSync &&
							shortDateTimeFormat.format(lastSync.syncedAt)
						}
						onClick={onSyncNow}
					>
						Online –{" "}
						{lastSync ? "Letzter Abruf:" : "Nicht synchronisiert"}
					</Item>
				) : (
					<Item
						thumb={<Icon type="cross-circle" color="#bbb" />}
						extra={
							syncStatus.offlineSince &&
							humanizeTimespan(
								syncStatus.offlineSince - Date.now()
							)
						}
						onClick={onSyncNow}
					>
						Offline – Kein Internet
						<Item.Brief style={{ marginTop: 0 }}>
							<FieldTimeOutlined style={{ marginRight: 5 }} />
							{syncStatus.pendingRequests} Meldungen ausstehend
						</Item.Brief>
					</Item>
				)}
				<Item thumb={<UserOutlined />} disabled={!auth.isLoggedIn}>
					{auth.isLoggedIn
						? `${auth.userInfo.lastName}, ${auth.userInfo.firstName}`
						: "Nicht angemeldet"}
				</Item>
				<Item
					arrow="horizontal"
					platform="android"
					thumb={<LogoutOutlined />}
					disabled={!auth.isLoggedIn}
					onClick={() =>
						auth.isLoggedIn &&
						Modal.alert(
							"Wirklich abmelden?",
							<>
								Nach der Abmeldung können keine Eingaben mehr
								gemacht werden.
								<br />
								Für das erneute Login ist eine{" "}
								<strong>Internetvebindung erforderlich!</strong>
							</>,
							[
								{ text: "Abbrechen" },
								{
									text: "Abmelden",
									style: "destructive",
									onPress: onTryLogout,
								},
							]
						)
					}
				>
					Abmelden
				</Item>
				<Item
					platform="android"
					className="danger"
					thumb={<DatabaseOutlined />}
					disabled={!auth.isLoggedIn}
					onClick={() =>
						auth.isLoggedIn &&
						Modal.alert(
							"Datenbank zurücksetzen?",
							<>
								Beim Zurücksetzen gehen unsynchronisierte
								Änderungen auf diesem Gerät verloren!
								<br />
								<strong>
									Eine Internetvebindung ist erforderlich!
								</strong>
							</>,
							[
								{ text: "Abbrechen" },
								{
									text: "Zurücksetzen",
									style: "destructive",
									onPress: onResetDatabase,
								},
							]
						)
					}
				>
					Datenbank zurücksetzen
				</Item>
			</List>
			<List renderHeader={() => "Benachrichtigungen"}>
				<Item extra={<Switch checked />}>Neuer Termin zugewiesen</Item>
			</List>
			<div className="app-version">
				{process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION}
			</div>
		</>
	);
}
export default Settings;
