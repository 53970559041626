import { HistoryOutlined, StopOutlined } from "@ant-design/icons";
import { Grid, Icon, List, Modal } from "antd-mobile";
import { useState } from "react";
import { toWorkMonths, toWorkWeeks } from "../util/datetime";

const Item = List.Item;

export function CustomerServiceInterval({
	serviceInterval,
	isDenied,
	onChange,
}) {
	const [modalVisible, setModalVisible] = useState(false);

	return (
		<>
			<Item
				platform="android"
				thumb={
					isDenied ? (
						<Icon
							type="cross-circle-o"
							style={{ color: "#f4333c" }}
						/>
					) : (
						<HistoryOutlined />
					)
				}
				className="customer-service-interval"
				onClick={() => setModalVisible(true)}
			>
				{isDenied ? (
					<span className="text-danger">Nicht behandeln!</span>
				) : !serviceInterval || serviceInterval < 0 ? (
					`Nicht angegeben`
				) : serviceInterval < 7 ? (
					`Jede Woche behandeln`
				) : serviceInterval >= 90 ? (
					`Alle ${toWorkMonths(serviceInterval)} Monate behandeln`
				) : (
					`Alle ${toWorkWeeks(serviceInterval)} Wochen behandeln`
				)}
			</Item>
			{modalVisible && (
				<CustomerServiceIntervalModal
					interval={serviceInterval}
					onCancel={() => setModalVisible(false)}
					onSubmit={(interval) => {
						onChange({ interval, isDenied: interval <= 0 });
						setModalVisible(false);
					}}
				/>
			)}
		</>
	);
}

function CustomerServiceIntervalModal({ interval, onCancel, onSubmit }) {
	const [selectedInterval, setSelectedInterval] = useState(interval);

	const suggestions = [
		{ value: 5, label: "1", unit: "Woche" },
		{ value: 12, label: "2", unit: "Wochen" },
		{ value: 19, label: "3", unit: "Wochen" },
		{ value: 26, label: "4", unit: "Wochen" },
		{ value: 33, label: "5", unit: "Wochen" },
		{ value: 40, label: "6", unit: "Wochen" },
		{ value: 54, label: "8", unit: "Wochen" },
		{ value: 90, label: "3", unit: "Monate" },
		{ value: 180, label: "6", unit: "Monate" },
	];

	return (
		<Modal
			visible={true}
			transparent
			onClose={(e) => {
				console.log("modal close:", e);
				onCancel();
			}}
			title="Behandlungsintervall"
			footer={[
				{
					text: (
						<>
							<StopOutlined style={{ marginRight: 8 }} /> Halt
						</>
					),
					style: "destructive",
					onPress: () => {
						onSubmit(0 /* Halt */);
					},
				},
				{
					text: "Anpassen",
					onPress: () => {
						onSubmit(selectedInterval);
					},
				},
			]}
		>
			<Item>
				<Grid
					className="customer-service-interval-grid"
					square={false}
					data={suggestions}
					columnNum={3}
					renderItem={({ value, label, unit }) => (
						<div
							className={`service-interval ${
								selectedInterval === value ? "selected" : ""
							}`}
						>
							<span className="service-interval-label">
								{label}
							</span>
							<small className="service-interval-unit">
								{unit}
							</small>
						</div>
					)}
					onClick={({ value }) => {
						setSelectedInterval(value);
					}}
				/>
			</Item>
		</Modal>
	);
}
