import { SendOutlined } from "@ant-design/icons";
import { Flex, List, TextareaItem } from "antd-mobile";
import * as ObjectID from "bson-objectid";
import { useEffect, useRef, useState } from "react";
import { useSubmitLogMessage } from "../data/mutations";
import { useEmployees, useWorkplace, useWorkplaceLogs } from "../hooks/data";
import { useIndex } from "../hooks/ramda";
import { classes } from "../util/classes";
import { dateTimeFormat } from "../util/datetime";
import "./MessageLog.less";
import { PageLoading } from "./PageLoading";

function LogMessage({ message, createdAt, updatedAt, recordedAt, employee }) {
	const date = new Date(recordedAt);

	return (
		<div
			className={classes(
				"log-message",
				!employee && "preparation-message"
			)}
		>
			{employee && (
				<h6 className="log-message-header">
					{employee.firstName} {employee.lastName}
				</h6>
			)}
			<p className="log-message-content">{message}</p>
			<small className="log-message-meta">
				{dateTimeFormat.format(date)}
				{updatedAt !== createdAt && (
					<span className="log-message-updated">
						geändert am {dateTimeFormat.format(new Date(updatedAt))}
					</span>
				)}
			</small>
		</div>
	);
}

function ScrollMarker({ messageCount }) {
	const ref = useRef(null);

	useEffect(() => {
		ref.current.scrollIntoView();
	}, []);

	useEffect(() => {
		ref.current.scrollIntoView({ behavior: "smooth" });
	}, [messageCount]);

	return <div className="log-message-scroll-marker" ref={ref}></div>;
}

export function MessageLog({ order }) {
	const { data: employees } = useEmployees();
	const { data: workplace } = useWorkplace(order.workplace);
	const { data: logs, refetch } = useWorkplaceLogs(order.workplace);

	const employeesById = useIndex((e) => e.id, employees);

	const mutation = useSubmitLogMessage();
	const [message, setMessage] = useState("");

	if (!workplace || !employees || !logs) {
		return <PageLoading />;
	}

	return (
		<div className="message-log">
			<div style={{ flex: 1, overflow: "auto" }}>
				{logs.map((msg) => (
					<LogMessage
						key={msg.id}
						{...msg}
						employee={employeesById[msg.employee]}
					/>
				))}
				<ScrollMarker messageCount={logs.length} />
			</div>
			<Flex direction="row" align="stretch" className="log-input-line">
				<TextareaItem
					autoHeight
					labelNumber={5}
					placeholder="Geben Sie eine Nachricht ein..."
					value={message}
					onChange={setMessage}
				/>
				<List.Item
					onClick={async () => {
						console.log(message);
						await mutation.mutateAsync({
							id: ObjectID().toHexString(),
							order,
							message,
						});
						await refetch();
						setMessage("");
					}}
				>
					<SendOutlined style={{ color: "#33A3F4" }} />
				</List.Item>
			</Flex>
		</div>
	);
}
