import { useContext, useEffect } from "react";
import { NavigationContext } from "../contexts/navigation";

export function useSearch() {
	const nav = useContext(NavigationContext);

	useEffect(() => {
		if (!nav) return;
		nav.setSearchVisible(true);
		return () => nav.setSearchVisible(false);
	}, [nav]);

	return nav.searchQuery;
}
