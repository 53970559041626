/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserEmployeeModel } from '../models/UserEmployeeModel';
import type { UserModel } from '../models/UserModel';
import type { UserModelUserEmployee } from '../models/UserModelUserEmployee';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UserControllerService {

    /**
     * @returns UserEmployeeModel All users
     * @throws ApiError
     */
    public static getUsers(): CancelablePromise<Array<UserEmployeeModel>> {
        return __request({
            method: 'GET',
            path: `/users`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns UserModel User updated
     * @throws ApiError
     */
    public static updateUser(
        userId: string,
        requestBody?: UserModel,
    ): CancelablePromise<UserModel> {
        return __request({
            method: 'PUT',
            path: `/users/${userId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `User not found`,
            },
        });
    }

    /**
     * @returns UserModelUserEmployee Success
     * @throws ApiError
     */
    public static getUserAuth(): CancelablePromise<UserModelUserEmployee> {
        return __request({
            method: 'GET',
            path: `/users/self`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}