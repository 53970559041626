export const dateFormat = new Intl.DateTimeFormat("de-DE", {
	year: "numeric",
	month: "2-digit",
	day: "2-digit",
});

export const dateTimeFormat = new Intl.DateTimeFormat("de-DE", {
	year: "numeric",
	month: "2-digit",
	day: "2-digit",
	hour: "2-digit",
	minute: "2-digit",
});

export const shortDateTimeFormat = new Intl.DateTimeFormat("de-DE", {
	weekday: "short",
	hour: "numeric",
	minute: "numeric",
	second: "numeric",
});

export const shortDateFormat = new Intl.DateTimeFormat("de-DE", {
	dateStyle: "medium",
});

export const shorterDateFormat = new Intl.DateTimeFormat("de-DE", {
	year: "2-digit",
	month: "2-digit",
	day: "2-digit",
});

export const weekDateFormat = new Intl.DateTimeFormat("de-DE", {
	weekday: "short",
	day: "numeric",
	month: "short",
});

export const timeFormat = new Intl.DateTimeFormat("de-DE", {
	timeStyle: "short",
});

export const timespanFormat = new Intl.RelativeTimeFormat("de-DE");

export const humanizeTimespan = (milliseconds) => {
	const seconds = Math.floor(milliseconds / 1000);
	if (seconds < 60 && seconds > -60) {
		return timespanFormat.format(seconds, "seconds");
	}

	const minutes = Math.floor(seconds / 60);
	if (minutes < 60 && minutes > -60) {
		return timespanFormat.format(minutes, "minutes");
	}

	const hours = Math.floor(minutes / 60);
	if (hours < 60 && hours > -60) {
		return timespanFormat.format(hours, "hours");
	}

	const days = Math.floor(hours / 24);
	return timespanFormat.format(days, "days");
};

export const timespanShortFormat = new Intl.RelativeTimeFormat("de-DE", {
	style: "short",
});

export const humanizeTimespanShort = (milliseconds) => {
	const seconds = Math.floor(milliseconds / 1000);
	if (seconds < 60 && seconds > -60) {
		return timespanShortFormat.format(seconds, "seconds");
	}

	const minutes = Math.floor(seconds / 60);
	if (minutes < 60 && minutes > -60) {
		return timespanShortFormat.format(minutes, "minutes");
	}

	const hours = Math.floor(minutes / 60);
	if (hours < 60 && hours > -60) {
		return timespanShortFormat.format(hours, "hours");
	}

	const days = Math.floor(hours / 24);
	return timespanShortFormat.format(days, "days");
};

export const toWorkWeeks = (days) => Math.floor((days + 2) / 7);
export const toWorkMonths = (days) => Math.floor((days + 2) / 30);
export const toWorkDays = (weeks) => weeks * 7 - 2;

export function getOrderTimespan(orderDate) {
	const min = new Date(orderDate);
	min.setHours(0);
	min.setMinutes(0);
	min.setSeconds(0);
	min.setMilliseconds(0);
	const max = new Date(min.getTime() + 24 * 1000 * 60 * 60);
	return [min, max];
}
