import { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { queryClient } from "../data/database";

export interface SyncStatus {
	status: "pending" | "synced";
	pendingRequests?: number;
	offlineSince?: number;
}

export function fetchSyncStatus(): Promise<SyncStatus> {
	return fetch("/_sw/sync-status").then((res) => {
		if (res.headers.get("Content-Type") !== "application/json")
			return { status: "synced" };
		return res.json();
	});
}

export function useSyncStatus(): SyncStatus {
	const { data } = useQuery("sync-status", fetchSyncStatus);

	const messageCallback = useCallback((event) => {
		if (event.data && event.data.type === "SYNC_STATUS_CHANGED") {
			queryClient.invalidateQueries("sync-status");
		}
	}, []);

	useEffect(() => {
		navigator.serviceWorker.addEventListener("message", messageCallback);
		return () => {
			navigator.serviceWorker.addEventListener(
				"message",
				messageCallback
			);
		};
	}, [messageCallback]);

	return data || { status: "pending" };
}
