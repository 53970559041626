import React, { useContext, useEffect, useState } from "react";
import { WorkerRegistrationContext } from "./worker";
import { DatabaseContext } from "./database";

const storageKey = "mfs_offline";

export const OfflineModeContext = React.createContext({
	isOffline: false,
	offlineSince: undefined,
	setOffline(offline) {},
});

export function OfflineModeProvider({ children }) {
	const [isOffline, setIsOffline] = useState(false);
	const [offlineSince, setOfflineSince] = useState(undefined);
	const worker = useContext(WorkerRegistrationContext);
	const db = useContext(DatabaseContext);

	useEffect(() => {
		const offlineState = localStorage.getItem(storageKey);
		console.log("restore offline state:", offlineState);
		setIsOffline(Boolean(offlineState));
		setOfflineSince(offlineState && Date.parse(offlineState));
	}, [setIsOffline, setOfflineSince]);

	const ctx = {
		isOffline,
		offlineSince,
		setOffline(offline) {
			setIsOffline(offline);
			if (offline) {
				console.log("switch to offline");
				const currentDate = new Date();
				setOfflineSince(currentDate);
				localStorage.setItem(storageKey, currentDate.toISOString());
			} else {
				console.log("switch back online");
				setOfflineSince(undefined);
				localStorage.removeItem(storageKey);
				worker.forceSync();
				db?.outbox.sync();
			}
		},
	};

	return (
		<OfflineModeContext.Provider value={ctx}>
			{children}
		</OfflineModeContext.Provider>
	);
}
