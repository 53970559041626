/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientCertSubject } from '../models/ClientCertSubject';
import type { EmployeeModel } from '../models/EmployeeModel';
import type { TokenResultModel } from '../models/TokenResultModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AuthControllerService {

    /**
     * @returns ClientCertSubject Success
     * @throws ApiError
     */
    public static getCertAuth(): CancelablePromise<ClientCertSubject> {
        return __request({
            method: 'GET',
            path: `/auth`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns EmployeeModel User authenticated
     * @throws ApiError
     */
    public static login(
        requestBody: {
            username: string;
            password: string;
        },
    ): CancelablePromise<EmployeeModel> {
        return __request({
            method: 'POST',
            path: `/auth/login`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authentication failed`,
            },
        });
    }

    /**
     * @param refreshToken
     * @returns TokenResultModel Token refreshed
     * @throws ApiError
     */
    public static refreshToken(
        refreshToken: string,
    ): CancelablePromise<TokenResultModel> {
        return __request({
            method: 'POST',
            path: `/auth/refresh-token`,
            cookies: {
                'refresh_token': refreshToken,
            },
            errors: {
                401: `Invalid token`,
            },
        });
    }

    /**
     * @param refreshToken
     * @returns void
     * @throws ApiError
     */
    public static logout(
        refreshToken: string,
    ): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/auth/logout`,
            cookies: {
                'refresh_token': refreshToken,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}