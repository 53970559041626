import { TagOutlined } from "@ant-design/icons";
import { List, Modal, TextareaItem } from "antd-mobile";
import { indexBy, prop } from "ramda";
import React from "react";
import { Item } from "./CustomerDetails";

export function CustomerAttributeList({
	attributes,
	customer,
	values,
	onChange,
}) {
	const attrMap = indexBy(prop("attr"), attributes);

	const attrKeys = Object.keys(values.attributes || {}).filter(
		(key) => values.attributes[key] !== null
	);

	return (
		/* <Icon className="list-header-button" type="plus" /> */
		<List renderHeader={() => "Eigenschaften"}>
			{attrKeys.map((key) => {
				const wasSet = customer.attributes[key];
				return (
					<TextareaItem
						autoHeight
						key={key}
						className="customer-attribute"
						title={(attrMap[key] || { name: key }).name}
						value={values.attributes[key]}
						clear={wasSet} // Allow clearing of saved attributes
						autoFocus={!wasSet} // Auto-focus newly added attributes
						onChange={(value) => {
							const fallback = wasSet ? null : "";
							onChange(key, value || fallback);
						}}
					/>
				);
			})}
			<Item
				platform="android"
				onClick={() => {
					Modal.operation(
						attributes
							.filter(({ attr }) => !values.attributes[attr])
							.map(({ attr, name }) => ({
								text: name,
								onPress: () => onChange(attr, ""),
							}))
					);
				}}
				thumb={<TagOutlined style={{ color: "black" }} />}
			>
				Eigenschaft hinzufügen
			</Item>
		</List>
	);
}
