import { Collection, IndexableType } from "dexie";
import { useContext, useEffect, useMemo, useRef } from "react";
import {
	MutationFunction,
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryResult,
} from "react-query";
import {
	CustomerControllerService,
	EmployeeControllerService,
	EmployeeModel,
	PriceListControllerService,
	WorkOrderControllerService,
	WorkplaceControllerService,
} from "../client";
import { AuthContext } from "../contexts/auth";
import { DatabaseContext } from "../contexts/database";
import { OfflineModeContext } from "../contexts/offline";
import {
	FriseurDatabase,
	ModelType,
	queryClient,
	TableName,
	TableNameOf,
} from "../data/database";

enum QueryType {
	SingleRow,
	Array,
}

/* const test = <
	K extends keyof FriseurDatabase,
	T extends FriseurDatabase[K] extends Dexie.Table<infer M> ? M : never
>(
	table: K
) => {
	const db = useContext(DatabaseContext);
	if (!db) return;
	return db[table];
};

const x = test("orders"); */

const makeQueryHook =
	<
		TName extends TableName,
		T extends FriseurDatabase[TName] extends Dexie.Table<infer M>
			? M
			: never,
		TRet extends T | T[],
		TParam extends any[],
		TKey extends IndexableType
	>(
		name: TName,
		mode: TRet extends T[] ? QueryType.Array : QueryType.SingleRow,
		fetch: (...args: TParam) => Promise<TRet>,
		query: (
			tbl: Dexie.Table<T, TKey>,
			auth: { isLoggedIn: boolean; userInfo: EmployeeModel | null },
			...args: TParam
		) => Collection<T, TKey>
	) =>
	(...args: TParam) => {
		const db = useContext(DatabaseContext);
		const auth = useContext(AuthContext);
		const offlineMode = useContext(OfflineModeContext);

		useEffect(() => {
			if (!db || args.some((a) => a === undefined)) return;
			const run = async (table: Dexie.Table) => {
				try {
					const fetched = await fetch(...args);
					console.log("fetched %s:", name, fetched);

					const result = Array.isArray(fetched)
						? await table.bulkPut(fetched)
						: await table.bulkPut([fetched]);

					console.log("updated offline %s:", name, result);
					queryClient.invalidateQueries(name, undefined, {});
				} catch (err) {
					console.error("update offline %s failed:", name, err);
					// console.log({ err });
					// if (err.status === StatusCodes.NOT_FOUND) {
					// 	console.log("pls delete");

					// 	const deleteCount = await query(
					// 		table,
					// 		...args
					// 	).delete();
					// 	console.log("deleted %s:", name, deleteCount);
					// }
				}
			};
			const tbl = db[name];
			if (!offlineMode.isOffline && tbl) run(tbl);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [db, offlineMode.isOffline, ...args]);

		return useQuery([name, ...args], async () => {
			if (!db) return;
			const tbl = db[name] as Dexie.Table<T, TKey>;
			if (!tbl) return [];

			console.log("query", mode, name, args);

			const qr = query(tbl, auth, ...args);

			if (mode === QueryType.SingleRow) {
				return await qr.first();
			} else {
				return await qr.toArray();
			}
		}) as UseQueryResult<TRet>;
	};

const makeQuerySingle =
	<T extends ModelType>(
		name: TableNameOf<T, string>,
		fetch: (key: string) => Promise<T>
	) =>
	(key: string) => {
		const db = useContext(DatabaseContext);
		const offlineMode = useContext(OfflineModeContext);

		useEffect(() => {
			if (!db || !key) return;
			const run = async (table: Dexie.Table) => {
				try {
					// if (await db.hasUnsyncedChanges()) {
					// 	console.log("skipping download, outstanding changes");
					// 	return;
					// }

					const item = await fetch(key);
					console.log("fetched %s:", name, key, item);

					const result = await table.put(item);
					console.log("updated offline %s:", name, key, result);
					queryClient.invalidateQueries([name, key], undefined, {});
				} catch (err) {
					console.error("update offline %s failed:", name, key, err);
				}
			};
			const tbl = db[name];
			if (!offlineMode.isOffline && tbl) run(tbl);
		}, [db, offlineMode.isOffline, key]);

		return useQuery([name, key], async () => {
			if (!db || !key) return;
			const tbl = db[name];
			if (!tbl) return undefined;
			return (await tbl.get(key)) as T;
		});
	};

export function useDataMutation<T extends ModelType>(
	name: TableNameOf<T, string>,
	mutation: MutationFunction<T, T>
): UseMutationResult<T, unknown, T>;
export function useDataMutation<T extends ModelType>(
	name: TableNameOf<T, string>,
	mutation: MutationFunction<void, T>
): UseMutationResult<void, unknown, T>;

export function useDataMutation<T extends ModelType>(
	name: TableNameOf<T, string>,
	mutation: MutationFunction<T | void, T>
) {
	const db = useContext(DatabaseContext);
	const offlineMode = useContext(OfflineModeContext);

	const mutator = offlineMode.isOffline
		? (v: T): ReturnType<typeof mutation> => {
				mutation(v)
					.then((res) => {
						console.log(
							"offline mutation succeeded unexpectedly",
							res
						);
					})
					.catch((err) => {
						console.log(
							"offline mutation failed, this is expected",
							err
						);
					});
				// No return
				return undefined as any;
		  }
		: mutation;

	/*
	const mutator = useCallback((v: T) => {
		if (!db) throw new Error("Missing database");

	}, [db]);*/

	return useMutation(mutator, {
		onMutate: async (data: T) => {
			if (!db) throw new Error("Missing database");
			const tbl = db[name] as Dexie.Table<T>;
			console.log("mutate off", name, data);

			// Add change to outbox
			await db.outbox.add({
				table: name,
				createdAt: new Date(),
				data,
			});

			// Update local copy for offline capabilities
			return await tbl.put(data);
		},
		onSuccess: (result, _, key) => {
			// If the mutation returned the updated model
			if (result && db) {
				const tbl = db[name] as Dexie.Table<T>;
				console.log("updated", name, result);

				// Update local copy with result of PUT
				tbl.put(result);
			}
			// Invalidate any queries on that record
			queryClient.invalidateQueries([name, key], undefined, {});
		},
		onError: (err, data, key) => {
			console.error("update %s failed", name, data, err);
			// Mark record as pending in local database
			if (key && db) {
				const tbl = db[name] as Dexie.Table<T>;
				console.log("mutation pending", name, key);
				tbl.update(key, { pendingMutation: true });
			}
		},
	});
}

export function useMutationState<T extends ModelType>(
	mutation: UseMutationResult
) {
	const changes = useRef<Partial<T>>({});
	return {
		changes: changes.current,
		save: (model: T) => mutation.mutate({ ...model, ...changes.current }),
	};
}

export function useOutboxMutation<T extends ModelType>(
	name: TableNameOf<T, string>
) {
	const db = useContext(DatabaseContext);
	const offlineMode = useContext(OfflineModeContext);

	// const mutator = offlineMode.isOffline

	return useMutation(
		async (data: T): Promise<IndexableType> => {
			if (!db) throw new Error("Missing database");
			const tbl = db[name] as Dexie.Table<T>;
			console.log("mutate off", name, data);

			// Add change to outbox
			await db.outbox.add({
				table: name,
				createdAt: new Date(),
				data,
			});

			// Update local copy for offline capabilities
			const ret = await tbl.put(data);

			if (!offlineMode.isOffline) {
				await db.outbox.sync();
			}

			return ret;
		}
		// {
		// 	onMutate: async (data: T) => {
		// 		if (!db) throw new Error("Missing database");

		// 		// Add change to outbox
		// 		await db.outbox.add({
		// 			table: name,
		// 			createdAt: new Date(),
		// 			data,
		// 		});
		// 	},
		// 	onSuccess: (result, _, key) => {
		// 		// If the mutation returned the updated model
		// 		if (result && db) {
		// 			const tbl = db[name] as Dexie.Table<T>;
		// 			console.log("updated", name, result);

		// 			// Update local copy with result of PUT
		// 			tbl.put(result);
		// 		}
		// 		// Invalidate any queries on that record
		// 		queryClient.invalidateQueries([name, key], undefined, {});
		// 	},
		// 	onError: (err, data, key) => {
		// 		console.error("update %s failed", name, data, err);
		// 		// Mark record as pending in local database
		// 		if (key && db) {
		// 			const tbl = db[name] as Dexie.Table<T>;
		// 			console.log("mutation pending", name, key);
		// 			tbl.update(key, { pendingMutation: true });
		// 		}
		// 	},
		// }
	);
}

export function useOutboxStatus() {
	const db = useContext(DatabaseContext);
	const { data } = useQuery("outbox", async () => {
		const count = await db?.outbox.getUnsyncedItems();
		return count;
	});

	return useMemo(() => {
		return { unsyncedItems: data };
	}, [data]);
}

export const useCustomers = makeQueryHook(
	"customers",
	QueryType.Array,
	CustomerControllerService.getCustomers,
	(tbl) => tbl.toCollection()
);

export const useCustomer = makeQuerySingle("customers", (key) =>
	CustomerControllerService.getCustomer(key)
);

export const useWorkplaces = makeQueryHook(
	"workplaces",
	QueryType.Array,
	WorkplaceControllerService.getWorkplaces,
	(tbl) => tbl.toCollection()
);

export const useWorkplace = makeQuerySingle("workplaces", (key) =>
	WorkplaceControllerService.getWorkplace(key)
);

export const useWorkplaceCustomers = makeQueryHook(
	"customers",
	QueryType.Array,
	(workplaceId: string) =>
		WorkplaceControllerService.getWorkplaceCustomers(workplaceId),
	(tbl, _, workplace) => tbl.where({ workplace })
);

export const useWorkplaceLogs = makeQueryHook(
	"workLog",
	QueryType.Array,
	(workplaceId: string) =>
		WorkplaceControllerService.getWorkplaceLog(workplaceId),
	(tbl, _, workplace) => tbl.where({ workplace }).filter((m) => !m.deletedAt)
);

export const usePriceList = makeQuerySingle("prices", (key) =>
	PriceListControllerService.getPriceList(key)
);

export const useOrders = makeQueryHook(
	"orders",
	QueryType.Array,
	WorkOrderControllerService.getWorkOrders,
	(tbl, auth) =>
		tbl.filter(
			(o) =>
				!o.billedAt && !o.deletedAt && o.employee === auth.userInfo?.id
		)
);

export const useOrder = makeQuerySingle("orders", (id) =>
	WorkOrderControllerService.getWorkOrder(id)
);

export const useEmployees = makeQueryHook(
	"employees",
	QueryType.Array,
	EmployeeControllerService.getEmployees,
	(tbl) => tbl.toCollection()
);

export const useEmployee = makeQuerySingle("employees", (id) =>
	EmployeeControllerService.getEmployee(id)
);

export const useServices = makeQueryHook(
	"services",
	QueryType.Array,
	WorkOrderControllerService.getWorkOrderServices,
	(tbl, _, order) => tbl.where({ order })
);

export const useCustomerOrderedService = makeQueryHook(
	"services",
	QueryType.SingleRow,
	WorkOrderControllerService.getCustomerOrderedService,
	(tbl, _, order, customer) => tbl.where({ order, customer })
);

export const useCustomerServices = makeQueryHook(
	"services",
	QueryType.Array,
	CustomerControllerService.getCustomerServices,
	(tbl, _, customer) => tbl.where({ customer })
);

export const useAttributes = makeQueryHook(
	"attributes",
	QueryType.Array,
	CustomerControllerService.getCustomerAttributes,
	(tbl) => tbl.toCollection()
);

export const useFullOrderSync = () => {
	const db = useContext(DatabaseContext);
	useEffect(() => {
		db?.syncOrders();
	}, [db]);
};

export const useSyncLogStatus = (table: string) => {
	const db = useContext(DatabaseContext);
	useEffect(() => {
		db?.getLastOrderSync();
	}, [db]);

	return useQuery(["syncLog", table], async () => {
		if (!db) return;
		return await db.syncLog.get(table);
	});
};

// export const useCustomers = () => {
// 	const db = useContext(DatabaseContext);

// 	useEffect(() => updateOfflineData(db), [db]);
// 	return useQuery("customers", () => db.customers.toArray());
// };

// export const useSubmitWorkOrderService = () => {
// 	const nav = useContext(NavigationContext);
// 	const submission = useMutation(
// 		(service: ServiceModelSubmission) => {
// 			const orderId =
// 				(service.order as WorkOrderModel).id ||
// 				(service.order as string);
// 			const customerId =
// 				(service.customer as CustomerModel).id ||
// 				(service.customer as string);

// 			return WorkOrderControllerService.submitWorkOrderService(
// 				orderId,
// 				customerId,
// 				service
// 			);
// 		},
// 		{
// 			onSuccess({ order, customer }) {
// 				nav.push(`/order/${order.id}/customer/${customer.id}`);
// 			},
// 		}
// 	);

// 	return submission;
// };
