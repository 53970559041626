import { KeyOutlined, LoginOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Flex, InputItem, Toast, WingBlank } from "antd-mobile";
import { createForm } from "rc-form";
import { Component, useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../contexts/auth";
import "./Login.less";

function Login() {
	const auth = useContext(AuthContext);

	const onHandleLogin = async ({ username, password }) => {
		console.log("onSubmitForm:", username, password);
		return auth.login(username, password);
	};

	if (auth.isLoggedIn) return <Redirect to="/" />;

	return (
		<Flex
			className="login"
			direction="column"
			justify="center"
			align="center"
		>
			<WingBlank>
				<Card>
					<Card.Header
						title={
							<>
								<UserOutlined />
								<h2>Login</h2>
							</>
						}
					/>
					<Card.Body>
						<LoginForm handleLogin={onHandleLogin} />
					</Card.Body>
				</Card>
				{/* <List
					className="login-form"
					renderHeader={
						<>
							<UserOutlined />
							<h2>Login</h2>
						</>
					}
				>
					<InputItem type="text" placeholder="Hier eingeben ...">
						Benutzer
					</InputItem>
					<InputItem type="password" placeholder="">
						Passwort
					</InputItem>
					<Item>
						<Button type="success" icon={<LoginOutlined />}>
							Login
						</Button>
					</Item>
				</List> */}
			</WingBlank>
		</Flex>
	);
}

export default Login;

class LoginFormComponent extends Component {
	submit = () => {
		console.log("submit");
		this.props.form.validateFields(async (error, value) => {
			if (error) return;

			const result = await this.props.handleLogin(value);
			if (!result) {
				this.props.form.resetFields();
				this.inputUsername.focus();
				Toast.fail("Anmeldung fehlgeschlagen", 2);
			}
		});
	};

	render() {
		const { getFieldProps, getFieldError } = this.props.form;

		const onHandleSubmit = (e) => {
			e.preventDefault();
			this.submit();
		};

		return (
			<form onSubmit={onHandleSubmit}>
				<InputItem
					{...getFieldProps("username", {
						rules: [{ required: true }],
					})}
					error={getFieldError("username")}
					type="text"
					placeholder="Benutzername"
					ref={(el) => (this.inputUsername = el)}
				>
					<UserOutlined />
				</InputItem>
				<InputItem
					{...getFieldProps("password", {
						rules: [{ required: true }],
					})}
					error={getFieldError("password")}
					type="password"
					placeholder="Kennwort"
				>
					<KeyOutlined />
				</InputItem>
				<Flex direction="row" justify="between" align="end">
					<span className="app-version">
						{process.env.REACT_APP_NAME}{" "}
						{process.env.REACT_APP_VERSION}
					</span>
					<Button
						className="login-button"
						inline
						type="primary"
						icon={<LoginOutlined />}
						onClick={this.submit}
					>
						Anmelden
					</Button>
					<button style={{ display: "none" }}>Submit</button>
				</Flex>
			</form>
		);
	}
}

const LoginForm = createForm()(LoginFormComponent);
