/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { NavigationContext } from "../contexts/navigation";

export function MenuSaveButton({ touched, onSave, isSubmitting }) {
	const nav = useContext(NavigationContext);

	useEffect(() => {
		if (!nav) return;
		const isFormTouched = Object.keys(touched).length;
		nav.setSaveAction("customer", isFormTouched ? { onSave } : null);
		return () => nav.setSaveAction("customer", null);
	}, [touched, onSave]);

	useEffect(() => {
		if (!nav) return;
		nav.setSaveSubmitting(isSubmitting);
		return () => nav.setSaveSubmitting(false);
	}, [isSubmitting]);
	return null;
}
