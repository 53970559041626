/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttributeModel } from '../models/AttributeModel';
import type { AttributeModelCreation } from '../models/AttributeModelCreation';
import type { CustomerModel } from '../models/CustomerModel';
import type { CustomerModelCreation } from '../models/CustomerModelCreation';
import type { ServiceModel } from '../models/ServiceModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CustomerControllerService {

    /**
     * @returns CustomerModel All customers
     * @throws ApiError
     */
    public static getCustomers(): CancelablePromise<Array<CustomerModel>> {
        return __request({
            method: 'GET',
            path: `/customers`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns AttributeModel All attributes
     * @throws ApiError
     */
    public static getCustomerAttributes(): CancelablePromise<Array<AttributeModel>> {
        return __request({
            method: 'GET',
            path: `/customers/_attributes`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateCustomerAttributes(
        requestBody?: Array<AttributeModelCreation>,
    ): CancelablePromise<void> {
        return __request({
            method: 'PUT',
            path: `/customers/_attributes`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns CustomerModel Success
     * @throws ApiError
     */
    public static getCustomerChanges(): CancelablePromise<Array<CustomerModel>> {
        return __request({
            method: 'GET',
            path: `/customers/_changes`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param customerId
     * @param rev
     * @returns CustomerModel Changes acknowledged
     * @throws ApiError
     */
    public static ackCustomerChanges(
        customerId: string,
        rev: number,
    ): CancelablePromise<CustomerModel> {
        return __request({
            method: 'DELETE',
            path: `/customers/_changes/${customerId}`,
            query: {
                'rev': rev,
            },
            errors: {
                401: `Unauthorized`,
                404: `Customer not found`,
            },
        });
    }

    /**
     * @param customerNo
     * @returns CustomerModel Success
     * @throws ApiError
     */
    public static getCustomerByNo(
        customerNo: number,
    ): CancelablePromise<CustomerModel> {
        return __request({
            method: 'GET',
            path: `/customers/byNo`,
            query: {
                'customerNo': customerNo,
            },
            errors: {
                401: `Unauthorized`,
                404: `Customer not found`,
            },
        });
    }

    /**
     * @param customerNo
     * @param rev
     * @param requestBody
     * @returns CustomerModel Customer created
     * @throws ApiError
     */
    public static updateCustomerByNo(
        customerNo?: number,
        rev?: number,
        requestBody?: CustomerModelCreation,
    ): CancelablePromise<CustomerModel> {
        return __request({
            method: 'PUT',
            path: `/customers/byNo`,
            query: {
                'customerNo': customerNo,
                'rev': rev,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                412: `Customer was modified`,
            },
        });
    }

    /**
     * @param customerId
     * @returns CustomerModel Success
     * @throws ApiError
     */
    public static getCustomer(
        customerId: string,
    ): CancelablePromise<CustomerModel> {
        return __request({
            method: 'GET',
            path: `/customers/${customerId}`,
            errors: {
                401: `Unauthorized`,
                404: `Customer not found`,
            },
        });
    }

    /**
     * @param customerId
     * @param requestBody
     * @returns CustomerModel Customer updated
     * @throws ApiError
     */
    public static updateCustomer(
        customerId: string,
        requestBody?: CustomerModelCreation,
    ): CancelablePromise<CustomerModel> {
        return __request({
            method: 'PUT',
            path: `/customers/${customerId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param customerId
     * @returns ServiceModel Success
     * @throws ApiError
     */
    public static getCustomerServices(
        customerId: string,
    ): CancelablePromise<Array<ServiceModel>> {
        return __request({
            method: 'GET',
            path: `/customers/${customerId}/services`,
            errors: {
                401: `Unauthorized`,
                404: `Customer not found`,
            },
        });
    }

}